import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";

import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const balanceColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
 
  {
    dataField: "summaryId",
    text: "Summary Id",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "total",
    text: "Total",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];


class BalanceTallySummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      balance: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getBalance(0);
  }

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getBalance(page);
  }

  getBalance(page) {
    let params = {
      "page": page,
      "limit": this.state.pageSize
    };
   
    // console.log(params);
    this.generalStateUpdater("balance", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/balance-tally/get-balance-summary`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          {
            modalName: "View Balance Tally Summary",
            children: <ViewOnlyForm data={e} />,
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
          }
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("total");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
     
        <Card className="custom-card-1">
          <div className="title">
            Balance Tally - Summary
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={balanceColumn}
                  className="table1"
                  tableName="Balance Tally Summary"
                  keyField="srNo"
                  customPagination="custom"
                  {...this.renderTableData("balance")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default BalanceTallySummary;
