import * as types from "../actions/actionTypes";

export const sidebarCollapsed = function (state = false, action) {
  switch (action.type) {
    case types.SIDEBAR_TOGGLE: {
      state = !state;
      break;
    }
    default: {
      break;
    }
  }
  return state;
};

export const language = function (state = "en", action) {
  switch (action.type) {
    case types.LANGUAGE_CHANGE: {
      state = action.payload;
      break;
    }
    default:
  }
  return state;
};

export const rolePermission = function (state = [], action) {
  switch (action.type) {
    case types.GET_ALL_ROLE_PERMISSION_SUCCESS: {
      state = action.payload;
      break;
    }
    default:
  }
  return state;
}
