import * as CommonReducer from '../reducers/CommonReducers';
import Login from './Login';
import ModalReducer from './ModalReducer';

const reducer = {
  ...CommonReducer,
  auth: Login,
  modal: ModalReducer,
};

export default reducer;
