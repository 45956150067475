import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { RenderInputCustomType } from "../common/RenderCustomView";
import DynamicFormList from "./DynamicFormList";

const CreatePaymentCurrencyForm = (props) => {
  const [inputs, setInputs] = useState(
    props.data.map(({ value = "", defaultValue = "" }) => {
      if (!["", null, undefined].includes(value)) {
        return value;
      } else if (!["", null, undefined].includes(defaultValue)) {
        return defaultValue;
      }
      return "";
    })
  );

  const [loading, setLoading] = useState(false);
  const [formData, setFormDatas] = useState(props.data);
  const [network, setNetwork] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencyId, setCurrencyId] = useState("");
  const [currencyType, setCurrencyType] = useState("wallet");
  const [status, setStatus] = useState(true);

  const PostApi = (path, data, cb) => {
    MerchantAxiosInstance.post(path, data )
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          AddNoti(resp.data.message, { type: "info" });
        } else {
          AddNoti(ParseError(resp), { type: "error" });
        }
      })
      .catch((e) => {
        if(e.response) {
          if(e.response.data) {
            if(e.response.data.error) {
              AddNoti(e.response.data.error.field + ": " + e.response.data.error.message, { type: "error" });
            }
            else {
              AddNoti(ParseError(e), { type: "error" });
            }
          }
          else {
            AddNoti(ParseError(e), { type: "error" });
          }
        } else {
          AddNoti(ParseError(e), { type: "error" });
        }
      })
      .finally(() => {
        cb();
      });
  };

  const _postApi = PostApi;

  useEffect(() => {
    setFormDatas(props.data);
  }, [props.data]);

  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));
  const setFormData = (i, v) =>
    setFormDatas(Object.assign([...formData], { [i]: v }));

  const handleOnChange = (i, e, name) => { 
    if(name === "currencyId") {
      if(e.value != null) {
        let currencyVal = e.value.split('|');
        setCurrencyName(currencyVal[1]);
        setCurrencyId(currencyVal[0]);
        setInput(i,e.value);
      } else {
        setCurrencyName("");
        setCurrencyId(null);
        setInput(i,"");
      }
    } 
    else if(name === "currencyType") {
      setCurrencyType(e);
      setInput(i, e);
    }
    else if(name === "status") {
      setStatus(e);
      setInput(i, e);
    }
    else if(name === "network") {
      setNetwork(e);
      setInput(i, e);
    }
    else { 
      setInput(i, e);
    }

    // setInput(i, e);
  }


  const handleFormSubmit = () => {
    setLoading(true);
    const payload = {};
    payload.currencyId = currencyId;
    payload.currencyName = currencyName;
    payload.currencyType = currencyType;
    payload.status = status;

    if(currencyType === "blockchain") {
      payload["network"] = network;
    }
  
    _postApi(
        props.api,
        payload,
        () => {
          if (props.cb && typeof props.cb === "function") props.cb();
          setLoading(false);
        }
      );
    setLoading(false);
  }

  return (
    <div
      className={`custom-input-1 dynamic-form ${
        props.view === "horizontal" ? "" : "container"
      }`}
    >
      {formData.map(({ name, type, ...rest }, i) => (
        <>
        {(name !== "currencyName" && name !== "network") && 
            <RenderInputCustomType
              field={name}
              type={type}
              value={inputs[i]}
              key={i}
              setInput={(e) => setInput(i, e)}
              onChange={(e) => handleOnChange(i, e, name)}
              formData={formData}
              formValues={inputs}
              setFormData={setFormData}
              view="vertical"
              {...rest}
            />
          }
          {(name === "currencyName") && 
            <RenderInputCustomType
              field={name}
              type={type}
              value={currencyName}
              key={i}
              setInput={(e) => setInput(i, e)}
              onChange={(e) => handleOnChange(i, e, name)}
              formData={formData}
              formValues={inputs}
              setFormData={setFormData}
              view="vertical"
              {...rest}
            />
          }

          {currencyType === "blockchain" && name === "network" && (
            <RenderInputCustomType
              field={name}
              type={type}
              value={inputs[i]}
              key={i}
              setInput={(i, e) => setInput(i, e)}
              onChange={(e) => handleOnChange(i, e, name)}
              formData={formData}
              formValues={inputs}
              setFormData={setFormData}
              view="vertical"
              {...rest}
            />
          )}
        </>
      ))}

      <Button
        onClick={handleFormSubmit}
        variant="primary"
        style={{ width: "10rem", float: "right" }}
        disabled={loading}
      >
        Submit
      </Button>
    </div>
  );
};

export default CreatePaymentCurrencyForm;
