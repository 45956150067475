import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import { faSort } from "@fortawesome/free-solid-svg-icons";
import {
  textFilter,
  dateFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import DatePicker from "react-datepicker";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdater,
  IconHeader,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import { DynamicForm } from "../common/RenderCustomView";

const merchantDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "email",
    text: "Merchant Email",
    classes: "highlight",
    sort: true,
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "name",
    text: "Merchant Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "labelName",
    text: "Label Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "apiKey",
    text: "API Key",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "isActive",
    text: "Status",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell) return cell.map((e) => <ToggleModal {...e} />);
    },
  },
];

class MerchantKeys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchantKeys: { data: [], loading: false },
    };

    this.generalStateUpdater = GeneralStateUpdater.bind(this);
  }

  componentDidMount() {
    this.getAlMerchantKeys();
  }

  getAlMerchantKeys() {
    this.generalStateUpdater("merchantKeys", {
      path: "/merchant/get-merchant-keys",
      method: "POST",
      postProcessor: (e) => {
        const updateData = [
          { name: "uniqueId", value: e.uniqueId, disabled: true },
          { name: "globianceHiddenFee", defaultValue: e.globianceHiddenFee },
          {
            name: "globianceOfficialFee",
            defaultValue: e.globianceOfficialFee,
          },
          {
            name: "globianceAgentOneEmail",
            defaultValue: e.globianceAgentOneEmail,
          },
          {
            name: "globianceAgentOneFee",
            defaultValue: e.globianceAgentOneFee,
          },
          {
            name: "globianceAgentTwoEmail",
            defaultValue: e.globianceAgentTwoEmail,
          },
          {
            name: "globianceAgentTwoFee",
            defaultValue: e.globianceAgentTwoFee,
          },
        ];
        const updateApi = "/merchant/update-merchant-keys";

        const updateStatusData = [
          { name: "uniqueId", value: e.uniqueId, disabled: true },
          {
            name: "status",
            defaultValue: e.isActive,
            type: "radio",
            options: [
              { label: "Acitve", value: true },
              { label: "Deactive", value: false },
            ],
          },
        ];

        const updateStatusApi = "/merchant/update-merchant-key-status";

        e.name = e.firstName + " " + e.lastName;

        return {
          ...e,
          actions: [
            {
              modalName: "Merchant Key",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            {
              modalName: "Update Merchant Key: Fee Rate",
              children: <DynamicForm data={updateData} api={updateApi} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
              btnName: "Update",
              btnVariant: "warning",
            },
            {
              modalName: "Update Merchant Key: Status",
              children: <DynamicForm data={updateStatusData} api={updateStatusApi} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
              btnName: "Update Status",
              btnVariant: "danger",
            },
          ],
        };
      },
    });
  }

  renderTableData(name) {
    console.log("name", name, this.state[name]);
    if (this.state[name].loading === true) return TableLoader("email");
    return {
      data: this.state[name].data.map((e, i) => {
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant Keys: Fee Rate
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantDataColumn}
                  className="table1"
                  tableName="Refunds"
                  keyField="srNo"
                  onRefresh={this.getAlMerchantKeys.bind(this)}
                  {...this.renderTableData("merchantKeys")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantKeys;
