import React from "react";
import moment from "moment";
import { Button, Card, Col, Row } from "react-bootstrap";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import { DynamicActionBtn } from "../common/RenderCustomView";
import { API_MERCHANT_ROOT } from "../../helper/constant";

import CreateStorePageLayout from "./CreateStorePageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSort } from "@fortawesome/free-solid-svg-icons";

const PageLayoutDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "name",
    text: "File Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "type",
    text: "File Type",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },

  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

export class PageLayoutFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageLayout: { data: [], loading: false },
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getPageLayout();
  }

  getPageLayout() {
    const storeId = this.props.match.params.id;

    const deleteAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/remove-store-page-layout-file`;
    this.generalStateUpdater("PageLayout", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/get-store-layout-files`,
      method: "POST",
      data: { storeLayoutId: storeId },
      postProcessor: (e) => {
        const Delete = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={deleteAPI}
            options={{
              variant: "danger",
              name: "Delete",
              data: {
                fileId: e.uniqueId,
              },
            }}
            cb={() => this.getPageLayout()}
          />
        );

        return {
          ...e,
          actions: [
            {
              modalName: "View Page Layout File",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Delete
          ],
        };
      },
    });
  }

  createPageLayout() {
    const storeId = this.props.match.params.id;
    const data = [
      { name: "storeLayoutId", value: storeId, type: "text", disabled: true },
      { name: "file", type: "file", accept: ".ejs, .css" },
    ];

    const api = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/add-store-page-layout-file`;
    const renderCreateBtnConfigs = { ...RenderCreateBtn(data, api, "Add Page Layout File") };
    renderCreateBtnConfigs.children = <CreateStorePageLayout isUserApi={false} data={data} api={api} />;
    return renderCreateBtnConfigs;
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("name");
    return {
      data: this.state[name].data.map((e, i) => {
        if (e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if (e.updatedAt != null) {
          e.updatedAt = moment(e.updatedAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="d-flex justify-content-between align-items-center">
            <div className="title">
              Merchant: Page Layouts Files
              <div className="title--bottom"></div>
            </div>
            <div className="title">
              <Button
                onClick={() => this.props.history.goBack()}
                variant="primary"
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back
              </Button>
            </div>
          </div>


          <div className="card-body">
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={PageLayoutDataColumn}
                  className="table1"
                  tableName="Page Layout Files"
                  keyField="srNo"
                  createBtn={<ToggleModal {...this.createPageLayout()} />}
                  onRefresh={this.getPageLayout.bind(this)}
                  {...this.renderTableData("PageLayout")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default PageLayoutFiles;
