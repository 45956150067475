import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";

import { faSort } from "@fortawesome/free-solid-svg-icons";
import { textFilter, dateFilter } from "react-bootstrap-table2-filter";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
    DateFilter,
    IconHeader,
    DefaultHeadFormatter,
    GeneralStateUpdater,
    TableLoader,
} from "../../utils/Table";
import { Update } from "./Update";
import Base64 from "Base64";

const DataColumn = [
    {
        dataField: "srNo",
        text: "SR.NO",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "LabelName",
        text: "Label Name",
        classes: "highlight",
        sort: true,
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "apiKey",
        text: "API KEY",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "tolerance",
        text: "Tolerance",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "duration",
        text: "Duration",
        cellEdit: true,
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "confirmation",
        text: "Confirmation",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "actions",
        text: "Actions",
        formatter: (cell, row, rowIndex, x) => {
            if (cell) return cell.map((e) => <ToggleModal {...e} />);
        },
    },
];

class keysList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: { data: [], loading: false },
            uniqueId: "",
        };

        this.generalStateUpdater = GeneralStateUpdater.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params && this.props.match.params.email) {
            let email = Base64.atob(this.props.match.params.email);
            this.setState({ uniqueId: email });
            this.getAllUsers(email);
        }
    }

    getAllUsers(email) {
        console.log("Props:", this.props.data);
        //   const email = this.props.data.email
        this.generalStateUpdater("email", {
            path: "/merchant/getlist",
            data: { email },
            method: "POST",
            postProcessor: (e) => {
                const updateData = [
                    {
                        name: "uniqueId",
                        value: e.uniqueId,
                        disabled: true,
                    },
                    {
                        name: "globianceHiddenFee",
                        defaultValue: e.globianceHiddenFee,
                    },
                    {
                        name: "globianceOfficialFee",
                        defaultValue: e.globianceOfficialFee,
                    },
                    {
                        name: "globianceAgentOneEmail",
                        defaultValue: e.globianceAgentOneEmail,
                    },
                    {
                        name: "globianceAgentOneFee",
                        defaultValue: e.globianceAgentOneFee,
                    },
                    {
                        name: "globianceAgentTwoEmail",
                        defaultValue: e.globianceAgentTwoEmail,
                    },
                    {
                        name: "globianceAgentTwoFee",
                        defaultValue: e.globianceAgentTwoFee,
                    },
                ];

                const api = "/merchant/updateKeys";
                return {
                    ...e,
                    actions: [
                        {
                            modalName: "Fee",
                            children: <Update data={updateData} api={api} />,
                            backdrop: "static",
                            centered: true,
                            disableSubmit: true,
                            keyboard: false,
                            btnName: "update",
                            btnVariant: "warning",
                        },
                    ],
                };
            },
        });
    }

    renderTableData(name) {
        if (this.state[name].loading === true) return TableLoader("email");
        return {
            data: this.state[name].data.map((e, i) => {
                return {
                    srNo: i + 1,
                    ...e,
                };
            }),
        };
    }

    render() {
        return (
            <div className='kyc main-panel'>
                <Card className='custom-card-1'>
                    <div className='title'>
                        List
                        <div className='title--bottom'></div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Table
                                    columns={DataColumn}
                                    className='table1'
                                    tableName='Details'
                                    keyField='srNo'
                                    onRefresh={this.getAllUsers.bind(
                                        this,
                                        this.state.uniqueId
                                    )}
                                    {...this.renderTableData("email")}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        );
    }
}

export default keysList;
