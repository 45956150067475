import { toast } from "react-toastify";
import store from "../redux/store";

export const AddNoti = (
  msg,
  {
    type = "info",
    position = "top-right",
    duration = 2000,
    hideProgressBar = false,
    closeOnClick = true,
    closeButton=true
  }
) => {
  const hasLoggedOut = store.getState().auth.hasLoggedOut;

  if(msg === null || hasLoggedOut) {
    return 0;
  } else {
    return toast(msg, {
      type: type,
      position,
      autoClose: duration,
      className: "custom-toast",
      hideProgressBar,
      closeOnClick,
      closeButton
    });
  }
};

export const RemoveNoti = (id) => {
  toast.dismiss(id);
};
