import React from "react";
import { DynamicActionBtn,DynamicForm, SelectOption } from "../common/RenderCustomView";
import Table from 'react-bootstrap/Table';
import {
  RenderUpdateBtn,
  GeneralStateUpdaterNew
} from "../../utils/Table";
import ToggleModal from "../common/ToggleModal";
import { API_MERCHANT_ROOT } from "../../helper/constant";

class AgentKeyTable extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      merchantKeyDetail: { data: this.props.merchantKeyDetail, loading: false },
      merchantDetail: { data: {}, loading: false },
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() { 
    this.getMerchantKeys();
  }

 getMerchantKeys() {
   this.generalStateUpdater("merchantDetail", {
     path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/get-merchant-key-by-id`,
     method: "POST",
     data: {"merchantKeyId":this.state.merchantKeyDetail.data.uniqueId},
   });
 }

  addAgentOneConfigBtn() {
    const { data } = this.state.merchantKeyDetail;
    const addAgentOneApi =
         `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/add-globiance-agent-one`;
       
        const addAgentOne = [
          { name: "merchantKeyId", value: data.uniqueId, disabled: true },

          { name: "globianceAgentOneId", defaultValue: data.globianceAgentOneId },
          { name: "globianceAgentOneFee", defaultValue: data.globianceAgentOneId },
        ];


    const renderUpdateBtnConfigs = { ...RenderUpdateBtn(addAgentOne, addAgentOneApi, "Add Agent", false, "Add Agent One") };
    renderUpdateBtnConfigs.children = <DynamicForm
                                        data={addAgentOne}
                                        api={addAgentOneApi}
                                      />;
    return renderUpdateBtnConfigs;
  }

  addAgentTwoConfigBtn() {
    const { data } = this.state.merchantKeyDetail;
    const addAgentTwoApi =
    `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/add-globiance-agent-two`;
       
    const addAgentTwo = [
      { name: "merchantKeyId", value: data.uniqueId, disabled: true },

      { name: "globianceAgentTwoId", defaultValue: data.globianceAgentTwoId },
      { name: "globianceAgentTwoFee", defaultValue: data.globianceAgentTwoFee },
    ];



    const renderUpdateBtnConfigs = { ...RenderUpdateBtn(addAgentTwo, addAgentTwoApi, "Add Agent", false, "Add Agent Two") };
    renderUpdateBtnConfigs.children = <DynamicForm
                                        data={addAgentTwo}
                                        api={addAgentTwoApi}
                                      />;
    return renderUpdateBtnConfigs;
  }
  

  render() {
    let data = {};
    if(Object.keys(this.state.merchantDetail.data).length > 0) {
      data = this.state.merchantDetail.data; 
    } 
     
    return (
      <>
        <div
          style={{ border: "1px solid #ccc", padding: "10px", display: "flex" }}
        >
          <div style={{ flex: "1" }}>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              {" "}
              Globiance Agent Config
            </div>
          </div>
          
        </div>
      {/* update settlement */}
        <div
          style={{ border: "1px solid #ccc", padding: "10px", display: "flex", marginTop:"10px" }}
        >
          
          <div style={{ flex: "1" }}>
          <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                            <th>Agent</th>
                            <th>Email</th>
                            <th>Fee</th>
                            <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Agent One</th>
                              <th>{data["globianceAgentOne.email"] ? data["globianceAgentOne.email"] : ""}</th>
                              <th>{data.globianceAgentOneFee ? data.globianceAgentOneFee : ""}</th>
                              <th>
                              {data["globianceAgentOne.email"] ? (
                                <span style={{ marginRight: "10px"}}>
                                <DynamicActionBtn
                                    api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/remove-globiance-agent-one`}
                                    type="actionBtnMerchant"
                                    options={{
                                      variant: "danger",
                                      name: "Remove Agent One",
                                      data: { merchantKeyId: this.state.merchantKeyDetail.data.uniqueId },
                                    }}
                                  />
                                </span>
                              ):
                              <span style={{ marginRight: "10px"}}>
                                <ToggleModal {...this.addAgentOneConfigBtn()} />
                              </span>
                              }
                              </th>
                            </tr>
                            <tr>
                              <th>Agent Two</th>
                              <th>{data["globianceAgentTwo.email"] ? data["globianceAgentTwo.email"] : ""}</th>
                              <th>{data.globianceAgentTwoFee ? data.globianceAgentTwoFee : ""}</th>
                              <th>
                              {data["globianceAgentTwo.email"] ? (
                                <span style={{ marginRight: "10px"}}>
                                <DynamicActionBtn
                                  api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/remove-globiance-agent-two`}
                                  type="actionBtnMerchant"
                                  options={{
                                    variant: "danger",
                                    name: "Remove Agent Two",
                                    data: { merchantKeyId: this.state.merchantKeyDetail.data.uniqueId },
                                  }}
                                />
                                </span>
                              ): 
                              <span style={{ marginRight: "10px"}}>
                                <ToggleModal {...this.addAgentTwoConfigBtn()} />
                              </span>
                              }  
                              
                              
                              </th>
                            </tr>
                          </tbody>
                        </Table>
              
              
            
          </div>

         
        </div>
      </>
    );
  }
}

export default AgentKeyTable;
