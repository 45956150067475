import React from "react";

import { DynamicActionBtn, DynamicForm } from "../common/RenderCustomView";

import ToggleModal from "../common/ToggleModal";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import {
    DateFilter,
    IconHeader,
    DefaultHeadFormatter,
    RenderUpdateBtn,
    GeneralStateUpdaterNew,
    TableLoader,
} from "../../utils/Table";
import { Col, Row } from "react-bootstrap";
import Table from "../common/Table";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const APM_CONFIGS_LIST = [
    { label: "GlobiancePay", value: "enableFIATBuy" },
    { label: "Truepay", value: "enableAPM_truepay" },
    { label: "Linepay", value: "enableAPM_linepay" },
    { label: "PromptQR", value: "enableAPM_promptqr" },
    { label: "Maya", value: "enableAPM_maya" },
    { label: "Gcash", value: "enableAPM_gcash" },
    { label: "Pix", value: "enableAPM_pix" },
    { label: "SPEI", value: "enableAPM_spei" },
    { label: "Moonpay", value: "enableAPM_moonpay" },
];

const merchantColumns = [
    {
        dataField: "srNo",
        text: "SR.NO",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "apmConfigLabel",
        text: "APM Config",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "apmConfigStatus",
        text: "Status",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "actions",
        text: "Actions",
        classes: "u-center",
        formatter: (cell, row, rowIndex, x) => {
            if (cell)
                return cell.map((e) => {
                    if (e.modalName) return <ToggleModal {...e} />;
                    return e;
                });
        },
    },
];
class ApmConfigKeyTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            keyDetail: { data: this.props.merchantKeyDetail, loading: false },
            merchantDetail: { data: {}, loading: false },
            apmConfigsData: [],
        };
        this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
    }

    componentDidMount() {
        this.getMerchantKeys();
    }

    componentDidUpdate(prevsProps, prevsState) {
        if (prevsState.merchantDetail.data !== this.state.merchantDetail.data) {
            this.processTableData();
        }
    }

    processTableData() {
        const { loading, data } = this.state.merchantDetail;
        let apmConfgTableData = [];
        if (!loading && Object.keys(data).length > 0) {
            Object.keys(data).forEach((key) => {
                const currentKeyData = APM_CONFIGS_LIST.find(
                    (config) => config.value === key
                );
                if (currentKeyData) {
                    apmConfgTableData.push({
                        apmConfigLabel: currentKeyData.label,
                        apmConfigKey: key,
                        apmConfigStatus: data[key],
                    });
                }
            });

            this.setState({ apmConfigsData: apmConfgTableData });
        }
    }

    getMerchantKeys() {
        this.generalStateUpdater("merchantDetail", {
            path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/get-merchant-key-by-id`,
            method: "POST",
            data: { merchantKeyId: this.state.keyDetail.data.uniqueId },
        });
    }

    updateApmConfiguration() {
        const updateSettlement = [
            {
                name: "merchantKeyId",
                value:
                    this.state.merchantDetail.data &&
                    this.state.merchantDetail.data.uniqueId,
                disabled: true,
            },
            {
                name: "apmName",
                type: "react-select",
                options: [...APM_CONFIGS_LIST],
            },
            {
                name: "status",
                type: "react-select",
                options: [
                    { label: "True", value: true },
                    { label: "False", value: false },
                ],
            },
        ];
        const updateSettlementApi = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/update-apm-status`;
        const renderUpdateBtnConfigs = {
            ...RenderUpdateBtn(
                updateSettlement,
                updateSettlementApi,
                "Update",
                false,
                "Update"
            ),
        };
        renderUpdateBtnConfigs.children = (
            <DynamicForm data={updateSettlement} api={updateSettlementApi} />
        );
        return renderUpdateBtnConfigs;
    }

    renderTableData(name) {
        if (this.state.merchantDetail.loading === true)
            return TableLoader("srNo");

        const updateSettlementApi = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/update-apm-status`;

        return {
            data: this.state.apmConfigsData.map((e, i) => {
                const Enable = (
                    <DynamicActionBtn
                        api={updateSettlementApi}
                        type='actionBtnMerchant'
                        options={{
                            variant: "warning",
                            name: "Enable",
                            data: {
                                merchantKeyId:
                                    this.state.merchantDetail.data &&
                                    this.state.merchantDetail.data.uniqueId,
                                apmName: e.apmConfigKey,
                                status: true,
                            },
                        }}
                    />
                );

                const Disable = (
                    <DynamicActionBtn
                        api={updateSettlementApi}
                        type='actionBtnMerchant'
                        options={{
                            variant: "danger",
                            name: "Disable",
                            data: {
                                merchantKeyId:
                                    this.state.merchantDetail.data &&
                                    this.state.merchantDetail.data.uniqueId,
                                apmName: e.apmConfigKey,
                                status: false,
                            },
                        }}
                    />
                );

                // const updateSettlement = [
                //     {
                //         name: "merchantKeyId",
                //         value:
                //             this.state.merchantDetail.data &&
                //             this.state.merchantDetail.data.uniqueId,
                //         disabled: true,
                //     },
                //     { name: "apmName", defaultValue: e.apmConfigKey, disabled: true },
                //     {
                //         name: "status",
                //         type: "select",
                //         defaultValue: e.apmConfigStatus,
                //         options: [
                //             { label: "Enable", value: true },
                //             { label: "Disable", value: false }
                //         ],
                //     },
                // ];
                return {
                    srNo: i + 1,
                    ...e,
                    actions: [
                        // {
                        //     modalName: "Update",
                        //     children: <DynamicForm data={updateSettlement} api={updateSettlementApi} />,
                        //     backdrop: "static",
                        //     centered: true,
                        //     disableSubmit: true,
                        //     keyboard: false,
                        //     btnName: "Update",
                        //     btnVariant: "warning",
                        //   },
                        Enable,
                        Disable,
                    ],
                };
            }),
        };
    }

    render() {
        return (
            <Row style={{ marginTop: "20px" }}>
                <Col lg={12} sm={12} md={12}>
                    <Table
                        columns={merchantColumns}
                        className='table1'
                        tableName='Apm Config Status'
                        keyField='srNo'
                        onRefresh={this.getMerchantKeys.bind(this)}
                        {...this.renderTableData("apmConfigsData")}
                    />
                </Col>
            </Row>
        );
    }
}

export default ApmConfigKeyTable;
