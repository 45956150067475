import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

import { RemoveExpo } from "../../helper/decimals"
import Table from "../common/Table";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import PieChart from "./PieChart";
import { DynamicActionBtn } from "../common/RenderCustomView";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const totalFeeCollectionDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "totalAmount",
    text: "Total Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }
]

const merchantFeeCollectionDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "totalAmount",
    text: "Total Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }
];

class GlobianceFeeCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feeCollection: { data: [], totalRecords:0, loading: false },
      feeChart: { data: [], loading: false },
      searchParams: { to: "", from: "" },
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantFeeCollection(0);
    this.getDataByFeeCollection();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };
    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
  
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;
  
    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00'; // Set "from" date to YYYY-MM-DD 00:00:00 format

    this.setState({ searchParams },()=> this.getMerchantFeeCollection(0));
    
  }

  handleChange = ({ currentTarget: input }) => { 
    
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  
  handleSearch = () => {
    this.getMerchantFeeCollection();
  }

  getMerchantFeeCollection() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    
    var fromdate = new Date();
    fromdate.setDate(fromdate.getDate() - 7);
    var fd = String(fromdate.getDate()).padStart(2, '0');
    var fm = String(fromdate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var fy = fromdate.getFullYear();

    fromdate = fm + '/' + fd + '/' + fy;

    const {to, from} = this.state.searchParams;
    var params = {
      to: moment(today).toISOString(),
      from: moment(fromdate).toISOString()
    };
 
    if(to != '') {
      params.to = moment(to).toISOString();
    }
    if(from != '') {
      params.from = moment(from).toISOString();
    }
   
    // console.log(params);
    this.generalStateUpdater("feeCollection", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/fee-collection/fee-collection-by-period`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        return {
          ...e,
          // actions: [],
        };
      },
    });
  }

  getDataByFeeCollection() {
    this.generalStateUpdater("feeChart", {
      method: "POST",
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/fee-collection/total-fee-collection`,
    });
    
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("currencyName");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD');
        }
        if(e.totalAmount) {
          e.totalAmount = RemoveExpo(e.totalAmount);
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
         <Card className="custom-card-1">
          <div className="title">
            Total Fee Collection
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={totalFeeCollectionDataColumn}
                  className="table1"
                  tableName="Total Fee Collection"
                  keyField="srNo"
                  onRefresh={this.getDataByFeeCollection.bind(this)}      
                  {...this.renderTableData("feeChart")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Globiance Fee Collection
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                    
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" defaultValue={this.state.searchParams.from} value={this.state.searchParams.from} name="from" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantFeeCollectionDataColumn}
                  className="table1"
                  tableName="Merchant Fee Collection"
                  keyField="srNo"
                  onRefresh={this.getMerchantFeeCollection.bind(this)}      
                  {...this.renderTableData("feeCollection")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default GlobianceFeeCollection;
