import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { AxiosInstance, MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { RenderInputCustomType } from "../common/RenderCustomView";

const BalanceWithdrawalRequestForm = (props) => {
  const [inputs, setInputs] = useState(
    props.data.map(({ value = "", defaultValue = "" }) => {
      if (!["", null, undefined].includes(value)) {
        return value;
      } else if (!["", null, undefined].includes(defaultValue)) {
        return defaultValue;
      }
      return "";
    })
  );
    
  const [loading, setLoading] = useState(false);
  const [formData, setFormDatas] = useState(props.data);
  const [withdrawalType, setWithdrawalType] = useState("");


  const PostApi = async(path, data) => {
    await MerchantAxiosInstance.post(path, data)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          AddNoti(resp.data.message, { type: "info" });
        } else {
          AddNoti(ParseError(resp), { type: "error" });
        }
      })
      .catch((e) => {
        AddNoti(ParseError(e), { type: "error" });
      })
      .finally(() => {
        //
      });
  };

  const _postApi = PostApi;

  useEffect(() => {
    setFormDatas(props.data);
  }, [props.data]);

  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));
  const setFormData = (i, v) =>
    setFormDatas(Object.assign([...formData], { [i]: v }));

  const handleOnChange = (i, e, name) => {
    setInput(i, e);
    if(name === "type") {
      setWithdrawalType(e);
    }
  }

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      const payload = props.data.reduce((acc, { name }, i) => {
        const query = {};
        if(inputs[i]) {
          query[name] = !isNaN(inputs[i]) && inputs[i] !== "" ? Number(inputs[i]) : inputs[i];
          if(withdrawalType === "internal" && name === "tag") {
            delete query[name];
          }
        }
        return { ...acc, ...query };
      }, {});
  
  
      await _postApi(props.api, payload);
      
    } catch (error) {
      AddNoti(error.message, { type: "error" });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      className={`custom-input-1 dynamic-form ${
        props.view === "horizontal" ? "" : "container"
      }`}
    >
      {formData.map(({ name, type, conditionalRender, ...rest }, i) => (
        <>
            {
              !conditionalRender && (
                <RenderInputCustomType
                  field={name}
                  type={type}
                  value={inputs[i]}
                  key={i}
                  setInput={(e) => setInput(i, e)}
                  onChange={(e) => handleOnChange(i, e, name)}
                  formData={formData}
                  formValues={inputs}
                  setFormData={setFormData}
                  view="vertical"
                  {...rest}
                />
              )
            }

            {
              name === "amount" && (
                <RenderInputCustomType
                  field={name}
                  type={type}
                  className="custom-input-append"
                  value={inputs[i]}
                  key={i}
                  setInput={(e) => setInput(i, e)}
                  onChange={(e) => handleOnChange(i, e, name)}
                  formData={formData}
                  formValues={inputs}
                  setFormData={setFormData}
                  view="vertical"
                  appendElement={<Button style={{ minWidth: "80px"}} type="button" onClick={() =>  setInput(i, formData[i].propValue)}>Max</Button>}
                  {...rest}
                />
              )
            }

            {withdrawalType === "external" && name === "tag" && (
              <RenderInputCustomType
                field={name}
                type={type}
                value={inputs[i]}
                key={i}
                setInput={(i, e) => setInput(i, e)}
                onChange={(e) => handleOnChange(i, e, name)}
                formData={formData}
                formValues={inputs}
                setFormData={setFormData}
                view="vertical"
                {...rest}
              />
            )}
        </>
      ))}

      <Button
        onClick={handleFormSubmit}
        variant="primary"
        style={{ width: "10rem", float: "right" }}
        disabled={loading}
      >
        Submit
      </Button>
    </div>
  );
};

export default BalanceWithdrawalRequestForm;