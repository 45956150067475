import React from "react";
import moment from "moment";
import { Card, Col, Row } from "react-bootstrap";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
    DateFilter,
    IconHeader,
    DefaultHeadFormatter,
    GeneralStateUpdaterNew,
    RenderCreateBtn,
    TableLoader,
    ViewOnlyForm,
} from "../../utils/Table";
import { DynamicActionBtn, SelectOption } from "../common/RenderCustomView";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";

import CreateStorePageLayout from "./CreateStorePageLayout";
import UpdateStorePageLayout from "./UpdateStorePageLayout";

const PageLayoutDataColumn = [
    {
        dataField: "srNo",
        text: "SR.NO",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "layoutName",
        text: "Layout Name",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "layoutType",
        text: "Layout Type",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "status",
        text: "Status",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "createdAt",
        text: "Created At",
        filter: dateFilter({
            onFilter: DateFilter("createdAt"),
        }),
        headerFormatter: DefaultHeadFormatter,
    },
    {
        dataField: "updatedAt",
        text: "Updated At",
        filter: dateFilter({
            onFilter: DateFilter("updatedAt"),
        }),
        headerFormatter: DefaultHeadFormatter,
    },
    {
        dataField: "actions",
        text: "Actions",
        classes: "u-center",
        formatter: (cell, row, rowIndex, x) => {
            if (cell)
                return cell.map((e, i) => {
                    if (e.modalName) return <ToggleModal key={i} {...e} />;
                    return e;
                });
        },
    },
];

export class PageLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PageLayout: { data: [], loading: false },
            currencyList: []
        };
        this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
    }

    componentDidMount() {
        this.getPageLayout();
    }

    getPageLayout() {
        const enableAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/enable-store-page-layout`;
        const disableAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/disable-store-page-layout`;
        const updateAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/update-store-page-layout`;
        this.generalStateUpdater("PageLayout", {
            path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/get-all-store-page-layout`,
            method: "POST",
            postProcessor: (e) => {

                let updateDataTable = [
                    { name: "storeLayoutId", value: e.uniqueId, disabled: true },
                    { name: "name", defaultValue: e.layoutName, type: "text", placeholder: "Please Enter Name" },
                    { name: "description", defaultValue: e.layoutDescription, type: "text", placeholder: "Please Enter Description" },
                    { name: "content", defaultValue: e.layoutContent, type: "text", placeholder: "Please Enter Content" },
                    { name: "thumbnail", type: "file", accept: ".png" },
                    {
                        name: "layoutType", defaultValue: e.layoutType, type: "select", options: [
                            { label: "Payment Page", value: "payment-page" },
                            { label: "Store Page", value: "store-page" },
                        ]
                    },
                    {
                        name: "status", defaultValue: e.status, type: "select", options: [
                            { label: "true", value: "true" },
                            { label: "false", value: "false" },
                        ]
                    },
                ];

                const Enable = (
                    <DynamicActionBtn
                        type="actionBtnMerchant"
                        api={enableAPI}
                        options={{
                            variant: "success",
                            name: "Enable",
                            data: {
                                storeLayoutId: e.uniqueId,
                            },
                        }}
                    />
                );

                const Disable = (
                    <DynamicActionBtn
                        type="actionBtnMerchant"
                        api={disableAPI}
                        options={{
                            variant: "danger",
                            name: "Disable",
                            data: {
                                storeLayoutId: e.uniqueId,
                            },
                        }}
                    />
                );

                const ViewStorePageFiles = (
                    <DynamicActionBtn
                        link={`/add-page-layout-file/${e.uniqueId}`}
                        type="navigateBtn"

                        options={{
                            variant: "info",
                            name: "View Store Page Layouts Files",
                            data: { storeLayoutId: e.uniqueId },
                        }}
                    />
                )

                return {
                    ...e,
                    actions: [
                        {
                            modalName: "View Page Layout",
                            children: <ViewOnlyForm data={e} />,
                            backdrop: "static",
                            centered: true,
                            disableSubmit: true,
                            keyboard: false,
                        },
                        Enable,
                        Disable,
                        {
                            modalName: "Update Page Layout",
                            children: <UpdateStorePageLayout isUserApi={false} data={updateDataTable} api={updateAPI} />,
                            backdrop: "static",
                            centered: true,
                            disableSubmit: true,
                            keyboard: false,
                            btnName: "Update",
                            btnVariant: "warning",
                        },
                        ViewStorePageFiles,
                    ],
                };
            },
        });
    }

    createPageLayout() {

        const data = [
            { name: "name", type: "text", placeholder: "Please Enter Name" },
            { name: "description", type: "text", placeholder: "Please Enter Description" },
            { name: "content", type: "text", placeholder: "Please Enter Content" },
            { name: "thumbnail", type: "file", accept: ".png" },
            {
                name: "layoutType", type: "select", defaultValue: "payment-page", options: [
                    { label: "Payment Page", value: "payment-page" },
                    { label: "Store Page", value: "store-page" },
                ]
            },
            {
                name: "status", type: "select", defaultValue: "true", options: [
                    { label: "true", value: "true" },
                    { label: "false", value: "false" },
                ]
            },
        ];

        const api = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/page-layout/create-store-page-layout`;
        const renderCreateBtnConfigs = { ...RenderCreateBtn(data, api, "Create Page Layout") };
        renderCreateBtnConfigs.children = <CreateStorePageLayout isUserApi={false} data={data} api={api} />;
        return renderCreateBtnConfigs;
    }

    renderTableData(name) {
        if (this.state[name].loading === true) return TableLoader("layoutName");
        return {
            data: this.state[name].data.map((e, i) => {
                if (e.createdAt != null) {
                    e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
                }
                if (e.updatedAt != null) {
                    e.updatedAt = moment(e.updatedAt).format('YYYY-MM-DD HH:mm:ss');
                }
                return {
                    srNo: i + 1,
                    ...e,
                };
            }),
        };
    }

    render() {
        return (
            <div className="user-wallet main-panel">
                <Card className="custom-card-1">
                    <div className="title">
                        Merchant: Page Layouts
                        <div className="title--bottom"></div>
                    </div>

                    <div className="card-body">
                        <Row className="mt-3 mt-md-5">
                            <Col lg={12} sm={12} md={12}>
                                <Table
                                    columns={PageLayoutDataColumn}
                                    className="table1"
                                    tableName="Page Layout"
                                    keyField="srNo"
                                    createBtn={<ToggleModal {...this.createPageLayout()} />}
                                    onRefresh={this.getPageLayout.bind(this)}
                                    {...this.renderTableData("PageLayout")}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        );
    }
}

export default PageLayout;
