import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm
} from "../../utils/Table";
import { DynamicActionBtn,DynamicForm, SelectOption } from "../common/RenderCustomView";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const merchantColumns = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "merchantUserId",
    text: "Merchant User Id",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "merchantUser.email",
    text: "Merchant Email",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "labelName",
    text: "Label Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "apiKey",
    text: "Api Key",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MerchantKey extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: { data: [], loading: false },
      currencyList: []
    };

    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    // this.getCurrencyList();
    this.getMerchantKeys();
    // this.getCurrencyList()
    // .then((currencyList) => {
    //   console.log("currency list is updated", currencyList);
    //   this.getMerchantKeys(); // Call getMerchantKeys() only after getCurrencyList() has completed and updated the state
    // })
    // .catch((error) => {
    //   // console.clear();
    //   console.log(error);
    //   console.log(error.response);
    // });
  }

  getCurrencyList = () => {
    return new Promise((resolve, reject) => {
      MerchantAxiosInstance.post("https://uatmerchantapi.globiance.com/admin-dashboard-api-service/currency/get-all-currency")
        .then((resp) => {
          if (resp.data.statusCode === 200 || resp.data.statusCode === 201) {
            this.setState({ currencyList: resp.data.data.rows });
            resolve(resp.data.data.rows); // resolve the promise with the data
          } else {
            AddNoti(ParseError(resp), { type: "error" });
            reject(resp.data.message); // reject the promise with an error message
          }
        })
        .catch((e) => {
          AddNoti(ParseError(e), { type: "error" });
          reject(e.message); // reject the promise with an error message
        });
    });
  };
  

  getMerchantKeys() {

    this.generalStateUpdater("currency", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/get-all-merchant-key`,
      method: "POST",
      postProcessor: (e) => {

        const View = (
          <DynamicActionBtn
            link={`/merchant-admin-keys-detail/${e.uniqueId}`}
            type="navigateBtn"
            options={{
              variant: "info",
              name: "View",
              data: e,
            }}
            />
        )


        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("merchantUserId");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }


  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant Keys
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantColumns}
                  className="table1"
                  tableName="Merchant Keys"
                  keyField="srNo"
                  onRefresh={this.getMerchantKeys.bind(this)}
                  {...this.renderTableData("currency")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantKey;