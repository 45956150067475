import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const InstaXchangeColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "checkoutId",
    text: "Checkout ID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),

  },
  {
    dataField: "link",
    text: "Widget Embed Link",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),

  },
  {
    dataField: "sessionId",
    text: "Session ID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "toAmount",
    text: "Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "fromCurrency",
    text: "From Currency",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "toCurrency",
    text: "To Currency",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "address",
    text: "Address",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amountDirection",
    text: "Amount Direction",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "method",
    text: "Method",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class InstaXchange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      instaXchange: { data: [], totalRecords: 0, loading: false },
      pageSize: 10,
      currentPage: 0,
      searchParams: { to: "", from: "" }
    };

    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantCheckout(0);
  }

  handleChange = ({ currentTarget: input }) => {
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };


  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantCheckout(page);
  }

  handleSearch = () => {
    this.getMerchantCheckout(0);
  }


  getMerchantCheckout(page) {

    const { to, from } = this.state.searchParams;
    let params = {
      "page": page,
      "limit": this.state.pageSize
    };

    if (to != '') {
      params.to = moment(to).toISOString();
    }
    if (from != '') {
      params.from = moment(from).toISOString();
    }

    this.generalStateUpdater("instaXchange", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/instax/get-all-instax-requests`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          {
            modalName: "View InstaXchange Record",
            children: <ViewOnlyForm data={e} />,
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
          }
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }

  renderTableData(name) {
    const { currentPage, pageSize } = this.state;
    let srNo = currentPage * pageSize;
    if (this.state[name].loading === true) return TableLoader("srNo");
    return {
      data: this.state[name].data.map((e, i) => {
        if (e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if (e.amountDistributed) {
          e.amountDistributed = e.amountDistributed.toLocaleString();
        }
        if (e.amount) {
          e.amount = e.amount.toLocaleString();
        }
        return {
          srNo: i + 1 + srNo,
          ...e,
        };
      }),
    };
  }


  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: InstaXchage
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
              <div className="card-body">
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>From:</Form.Label>
                      <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} style={{ height: "35px" }} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>To:</Form.Label>
                      <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} style={{ height: "35px" }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" type="button" onClick={() => this.handleSearch()} style={{ width: '125px', fontSize: '16px' }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={InstaXchangeColumn}
                  className="table1"
                  tableName="InstaXchage"
                  keyField="srNo"
                  customPagination="custom"
                  totalCount={this.state.instaXchange.totalRecords}
                  pageSize={this.state.pageSize}
                  pageNumber={this.state.currentPage}
                  onPageChange={this.handlePage}
                  {...this.renderTableData("instaXchange")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default InstaXchange;
