import React from "react";
import moment from "moment";
import { Card, Col, Row } from "react-bootstrap";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";

import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
    DateFilter,
    DefaultHeadFormatter,
    IconHeader,
    GeneralStateUpdaterNew,
    TableLoader,
    ViewOnlyForm,
} from "../../utils/Table";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";

const MerchantSupportClientRequestsColumns = [
    {
        dataField: "srNo",
        text: "SR.NO",
        sort: true,
        classes: "u-center",
    },
    {
        dataField: "clientName",
        text: "Client Name",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "clientEmail",
        text: "Client Email",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "businessName",
        text: "Business Name",
        filter: textFilter(),
        headerFormatter: DefaultHeadFormatter,
    },
    {
        dataField: "businessWebsite",
        text: "Business Website",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "typeOfBusiness",
        text: "Business Type",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "industry",
        text: "Industry",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "description",
        text: "Description",
        sort: true,
        classes: "u-center",
        headerClasses: "u-cursor-pointer",
        filter: textFilter(),
        headerFormatter: IconHeader(faSort),
    },
    {
        dataField: "createdAt",
        text: "Created At",
        filter: dateFilter({
            onFilter: DateFilter("createdAt"),
        }),
        headerFormatter: DefaultHeadFormatter,
    },
    {
        dataField: "updatedAt",
        text: "Updated At",
        filter: dateFilter({
            onFilter: DateFilter("updatedAt"),
        }),
        headerFormatter: DefaultHeadFormatter,
    },
    {
        dataField: "actions",
        text: "Actions",
        classes: "u-center",
        formatter: (cell, row, rowIndex, x) => {
            if (cell)
                return cell.map((e, i) => {
                    if (e.modalName) return <ToggleModal key={i} {...e} />;
                    return e;
                });
        },
    },
];

export class MerchantSupportClientRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientRequests: { data: [], totalRecords: 0, loading: false },
            pageSize: 10,
            currentPage: 0
        };
        this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
    }

    componentDidMount() {
        this.getMerchantClientRequests(0);
    }

    handlePage = (page) => {
        this.setState({ currentPage: page });
        this.getMerchantClientRequests(page);
    }

    getMerchantClientRequests(page) {
        let params = {
            "page": page,
            "limit": this.state.pageSize
          };
        this.generalStateUpdater("clientRequests", {
            path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/support/get-all-client-request`,
            method: "POST",
            data: params,
            postProcessor: (e) => {
                return {
                    ...e,
                    actions: [
                        {
                            modalName: "View Client Request",
                            children: <ViewOnlyForm data={e} />,
                            backdrop: "static",
                            centered: true,
                            disableSubmit: true,
                            keyboard: false,
                        },
                    ],
                };
            },
        });
    }

    renderTableData(name) {
        if (this.state[name].loading === true) return TableLoader("srNo");
        return {
            data: this.state[name].data.map((e, i) => {
                if (e.createdAt != null) {
                    e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
                }
                if (e.updatedAt != null) {
                    e.updatedAt = moment(e.updatedAt).format('YYYY-MM-DD HH:mm:ss');
                }
                return {
                    srNo: i + 1,
                    ...e,
                };
            }),
        };
    }

    render() {
        return (
            <div className="user-wallet main-panel">
                <Card className="custom-card-1">
                    <div>
                        <Link to='/merchant-support-tickets' className="btn btn-outline-dark tab-btn" style={{ marginRight: "10px"}}> Support Tickets</Link>
                        <Link to='/merchant-support-clients-requests' className="btn btn-outline-dark tab-btn active">Clients Requests</Link>
                    </div>
                </Card>
                <Card className="custom-card-1">
                    <div className="title">
                            Merchant: Client Requests
                        <div className="title--bottom"></div>
                    </div>

                    <div className="card-body">
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Table
                                    columns={MerchantSupportClientRequestsColumns}
                                    className="table1"
                                    tableName="Client Requests"
                                    keyField="srNo"
                                    customPagination="custom"
                                    totalCount={this.state.clientRequests.totalRecords}
                                    pageSize={this.state.pageSize}
                                    pageNumber={this.state.currentPage}
                                    onPageChange={this.handlePage}  
                                    onRefresh={() => this.getMerchantClientRequests(0)}
                                    {...this.renderTableData("clientRequests")}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        );
    }
}

export default MerchantSupportClientRequests;
