import * as types from './actionTypes';
import { MerchantAxiosInstance } from '../../helper/AxiosInstance';

/**
 * ----------------------------------------------------------
 * Simple Action Creators Start
 * ----------------------------------------------------------
 */

export const SidebarToggle = () => {
  return {
    type: types.SIDEBAR_TOGGLE,
  };
};

export const LanguageChange = () => {
  return {
    type: types.LANGUAGE_CHANGE,
  };
};

export const getDetailLocked = (data) => {
  return {
    type: types.DETAIL_LOCKED,
    projects: data,
  };
};

export const getDetailFlexible = (data) => {
  return {
    type: types.DETAIL_FLEXIBLE,
    projects: data,
  };
};

export const handleOpenModal = (Component) => {
  return {
    type: types.VISIBLE_MODAL,
    Component: Component,
  };
};

/**
 * ----------------------------------------------------------
 * Simple Action Creators Stop
 * ----------------------------------------------------------
 */

/**
 * ----------------------------------------------------------
 * Thunkified Actions Start
 * ----------------------------------------------------------
 */

export const GetAuthStatus = () => async (dispatch) => {
  try {
    const resp = await MerchantAxiosInstance.post('/admin-dashboard-api-service/auth/verify-jwt');
    if (resp.status !== 200) {
      dispatch({
        type: types.LOGOUT,
      });
    } else {
      dispatch({
        type: types.ADMIN_DATA,
        admin: resp.data.data,
      });

    }
  } catch (e) {
    dispatch({
      type: types.LOGOUT,
    });
  }
};


/**
 * ----------------------------------------------------------
 * Thunkified Actions End
 * ----------------------------------------------------------
 */
