import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import ReactSelect from "react-select";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import PieChart from "./PieChart";
import { DynamicActionBtn } from "../common/RenderCustomView";
import moment from "moment";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";   
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { SelectOption } from "../common/RenderCustomView";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const paymentStatusColumns = [
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "count",
    text: "Count",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
];

const merchantPaymentColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: ["MerchantKey.labelName"],
    text: "Store Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),

  },
  {
    dataField: ["User.email"],
    text: "Merchant Email",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),

  },
  {
    dataField: "actions",
    text: "Merchant Name",
    classes: "u-center",
    sort: true,
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),

    formatter: (cell, row, rowIndex, x) => {
      if (cell && cell.length > 0 && cell[0].props && cell[0].props.options && cell[0].props.options.data) {
        return cell.map((e) => {
          const firstName = e.props.options.data["User.firstName"];
          const lastName = e.props.options.data["User.lastName"];
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          } else if (firstName) {
            return firstName;
          } else if (lastName) {
            return lastName;
          } else {
            return "";
          }
        });
      } else {
        return "";
      }
    }
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "transactionType",
    text: "Transaction Type",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "isUnderDispute",
    text: "Under Dispute",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "disputeType",
    text: "Dispute Type",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "settlementStatus",
    text: "Settlement Status",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amountDistributed",
    text: "Amount Distributed",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  {
    dataField: "amountRefunded",
    text: "Amount Refunded",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  {
    dataField: "refunded",
    text: "Refunded",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "isActive",
    text: "Active",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  

  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MerchantPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payment: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
      statusChart: { data: [], loading: false },
      transactionChart: { data: [], loading: false },
      searchParams: { to: "", from: "", status: "", transactionType: "", currencyId:"", paymentId: "", merchantKeyId:"" },
      selectOptions: [],
      selectedOption:null,
      storeMapping: [],
      stores:[],
      merchantUserMapping: [],
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    // this.getMerchantCheckout(0);
    this.getDataByMerchant();
    this.getDataByStatus();
    this.getCurrencyList();
    this.getAllStores();
    this.getAllMerchantUsers();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };
    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
  
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;
  
    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00'; // Set "from" date to YYYY-MM-DD 00:00:00 format

    this.setState({ searchParams },()=> this.getMerchantCheckout(0));
  }

  handleChange = ({ currentTarget: input }) => { 
    console.log(input);
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handleSelect = (selectedOption) => { 
    const searchParams = { ...this.state.searchParams };
    if(selectedOption != null) {
      searchParams["currencyId"] = selectedOption.value;
  
      this.setState({ searchParams });
      this.setState({ selectedOption });
    } else {
      searchParams["currencyId"] = "";
      this.setState({ searchParams });
      this.setState({ selectedOption: null });
    }
  }

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantCheckout(page);
  }

  handleSearch = () => {
    this.getMerchantCheckout(0);
  }

  getAllStores()  {
		MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/get-all-merchant-key`)
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					
          this.setState({ store: resp.data.data});
          const storeMap =
          resp.data.data != null
            ? resp.data.data.map((store) => ({
                label: `${store.labelName}`,
                value: store.uniqueId,
              }))
            : [{ label: "Loading", value: "" }];

          const options = [...SelectOption, ...storeMap]
          console.log("==",storeMap);
          this.setState({storeMapping: options});

				} else {
					//AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
				//AddNoti(ParseError(e), { type: "error" });
			});
	};

  getMerchantCheckout(page) {
    
    const {to, from, status, currencyId, transactionType, paymentId, merchantKeyId, merchantEmail} = this.state.searchParams;
    let params = {
      "page": page,
      "limit": this.state.pageSize
    };
 
    if(to != '') {
      params.to = moment(to).toISOString();
    }
    if(from != '') {
      params.from = moment(from).toISOString();
    }
    if(status != '') {
      params.status = status;
    }
    if(currencyId != '') {
      params.currencyId = currencyId;
    }
    if(transactionType != '') {
      params.transactionType = transactionType;
    }
    if(paymentId != '') {
      params.paymentId = paymentId;
    }
    if(merchantKeyId != '') {
      params.merchantKeyId = merchantKeyId;
    }
    if(merchantEmail != '') {
      params.merchantEmail = merchantEmail;
    }

    // console.log(params);
    this.generalStateUpdater("payment", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/get-all-payments`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        e.parent = "Payments";
        const View = (
          <DynamicActionBtn
            link={`/merchant-admin-payment-detail/${e.uniqueId}`}
            type="navigateBtn"
            options={{
              variant: "info",
              name: "View",
              data: e,
            }}
            />
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }

  getDataByStatus() {
    this.generalStateUpdater("statusChart", {
      method: "POST",
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/get-stats-by-status`,
    });
    
  }

  getDataByMerchant() {
    this.generalStateUpdater("transactionChart", {
      method: "POST",
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/get-stats-by-transaction-type`,
    });
  }

  getCurrencyList = () => {
		MerchantAxiosInstance.post("/admin-dashboard-api-service/currency/get-all-currency")
			.then((resp) => {
				  if (resp.status === 200 || resp.statusCode === 201) {

          let options = [];
          let currencyList = resp.data.data.rows;
          if (currencyList.length > 0) {
            options = currencyList.map((currency) => {
              return { label: currency.symbol, value: `${currency.uniqueId}` };
            });
          }

          this.setState({ selectOptions: options});

				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
				AddNoti(ParseError(e), { type: "error" });
			});
	};

  renderTableData(name) {
    const { currentPage, pageSize } =  this.state;
    let srNo = currentPage * pageSize;
    if (this.state[name].loading === true) return TableLoader("srNo");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if(e.amountDistributed) {
          e.amountDistributed = e.amountDistributed.toLocaleString();
        }
        if(e.amount) {
          e.amount = e.amount.toLocaleString();
        }
        return {
          srNo: i + 1 + srNo,
          ...e,
        };
      }),
    };
  }

  getAllMerchantUsers()  {
		MerchantAxiosInstance.post(`admin-dashboard-api-service/merchant/get-all-merchant`)
			.then((resp) => {
        console.log("resp", resp)
				if (resp.status === 200 || resp.status === 201) {
					
          const merchantUsers =
          resp.data.data != null
            ? resp.data.data.rows.map((user) => ({
                label: user.email,
                value: user.email,
              }))
            : [{ label: "Loading", value: "" }];

          const options = [...SelectOption, ...merchantUsers]
          this.setState({ merchantUserMapping: options });

				} else {
					//AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((e) => {
				//AddNoti(ParseError(e), { type: "error" });
			});
	};

  renderPaymentStatusTable(name) {
    if (this.state[name].loading === true) return TableLoader("status");
    return {
      data: this.state[name].data.map((e) => {
        return {
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          
          <div className="card-body">
            <Row>
             <Col lg={6} >
             <div className="title">
                Payment Status
                <div className="title--bottom"></div>
              </div>
                <Col ol md={12} sm={12}>
                  <Table
                      columns={paymentStatusColumns}
                      className="table1"
                      tableName="Payment Status"
                      keyField="status"
                      sizePerPage={4}
                      {...this.renderPaymentStatusTable("statusChart")}
                    />
                </Col>
              </Col>
            </Row>
          </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Payments
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                  <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} style={{height: "35px" }} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange}  style={{height: "35px" }} />
                      </Form.Group>
                    </Col>
                   
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Status:</Form.Label>
                        <select className="form-control" name="status" onChange={this.handleChange} style={{ height: "35px"}}>
                          <option value="">select status</option>
                          <option value="processing">Processing</option>
                          <option value="confirmed">Confirmed</option>
                          <option value="refunded">Refunded</option>
                          <option value="disputed">Disputed</option>
                          <option value="partially refunded">Partially refunded</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Transaction Type:</Form.Label>
                        <select className="form-control" name="transactionType" onChange={this.handleChange} style={{ height: "35px"}}>
                          <option value="">select type</option>
                          <option value="blockchain">Blockchain</option>
                          <option value="wallet">Wallet</option>
                        </select>
                      </Form.Group>
                    </Col>

                    </Row>
                    <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Currency Id:</Form.Label>
                        <ReactSelect
                          defaultValue={this.state.selectedOption}
                          onChange={this.handleSelect}
                          options={this.state.selectOptions}
                          isClearable={true}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Payment ID:</Form.Label>
                        <Form.Control type="text" value={this.state.searchParams.paymentId} name="paymentId" onChange={this.handleChange} style={{height: "35px" }} />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Store:</Form.Label>
                        <ReactSelect defaultValue={""} options={this.state.storeMapping} onChange={(e) => {
                          this.setState({
                            searchParams: {
                              ...this.state.searchParams,
                              merchantKeyId: e.value
                            }
                          })
                        }} />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Merchant Email:</Form.Label>
                        <ReactSelect defaultValue={""} options={this.state.merchantUserMapping} onChange={(e) => {
                          this.setState({
                            searchParams: {
                              ...this.state.searchParams,
                              merchantEmail: e.value
                            }
                          })
                        }} />
                      </Form.Group>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()} style={{  width: '125px', fontSize:'16px'}}>
                        Search
                      </Button>
                    </Col>   
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantPaymentColumn}
                  className="table1"
                  tableName="Merchant Payments"
                  keyField="srNo"
                  customPagination="custom"
                  totalCount={this.state.payment.totalRecords}
                  pageSize={this.state.pageSize}
                  pageNumber={this.state.currentPage}
                  onPageChange={this.handlePage}                
                  {...this.renderTableData("payment")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantPayment;
