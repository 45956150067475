import React, { Component, useState } from "react";
import { connect } from "react-redux";

import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";

import { textFilter, numberFilter } from "react-bootstrap-table2-filter";

import * as actions from "../../redux/actions";

import Table from "../common/Table";

import { DefaultHeadFormatter, IconHeader } from "../../utils/Table";

import loader from "../../assets/gif/loader.gif";
import {
  ColorLightGreen,
  ColorLightGrey,
  ColorLightRed,
  CompareCurrencies,
} from "../../helper/constant";

const UserWalletSummary = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currency",
    text: "Curreny",
    classes: "highlight u-center",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "balance",
    text: "Balance",
    filter: numberFilter(),
    sort: true,
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "inTrade",
    text: "In-Trade",
    filter: numberFilter(),
    sort: true,
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "inWithdrawal",
    text: "In Withdrawal",
    filter: numberFilter(),
    sort: true,
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "staking_balance",
    text: "Staking Balance",
    filter: numberFilter(),
    sort: true,
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
];

const UserWalletSummaryInteractive = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currency",
    text: "Curreny",
    classes: "highlight u-center",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "balance",
    text: "User Balance",
    filter: numberFilter(),
    sort: true,
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "accounted",
    text: "accounted",
    filter: numberFilter(),
    sort: true,
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "adjustment",
    text: "Adjustment",
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "final",
    filter: numberFilter(),
    sort: true,
    text: "Final",
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
];

const AdminAssetSummary = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currency",
    text: "Curreny",
    classes: "highlight u-center",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "platform",
    text: "Asset Source",
    classes: "u-center",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "balance",
    text: "Balance",
    filter: numberFilter(),
    sort: true,
    classes: "u-right",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
];

const UserWalletSummaryLoader = {
  data: [
    {
      srNo: 1,
      currency: <img src={loader} alt="loading" width="20%" height="10rem" />,
    },
  ],
  customColSpan: true,
};

const CompareBalance = ({ data, tableName, columns }) => {
  const [inputs, setInputs] = useState(Array(data.length).fill(0));

  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));
  data = data.map((e, i) => {
    e.adjustment = (
      <input
        value={inputs[i]}
        onChange={(e) => {
          setInput(i, e.target.value);
        }}
      />
    );
    const effectiveAdjustment = isNaN(parseFloat(inputs[i]))
      ? 0
      : parseFloat(inputs[i]);

    e.final =
      parseFloat(e.accounted) - parseInt(e.balance) + effectiveAdjustment;

    return { ...e };
  });

  return (
    <div className="compare-balance">
      <Container>
        <Row>
          <Col lg={12} sm={12}>
            <Table
              columns={columns}
              className="table1"
              tableName={tableName}
              keyField="srNo"
              data={data}
              rowStyle={function (row, i) {
                row.final = parseFloat(row.final);
                if (row.final > 0) {
                  return { backgroundColor: ColorLightGreen };
                } else if (row.final < 0) {
                  return { backgroundColor: ColorLightRed };
                } else {
                  return { backgroundColor: ColorLightGrey };
                }
              }}
            />
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.renderWalletSummary = this.renderWalletSummary.bind(this);
    this.renderOwnedBalance = this.renderOwnedBalance.bind(this);
  }

  componentDidMount() {
    this.props.GetGlobianceWalletSummary();
    this.props.GetExchangeCurrency();
    this.props.GetPair();

    this.props.GetAdminAddress();
    this.props.GetBtcNodeBalance();
    // this.props.GetFineryPositions();
    this.props.GetB2C2Positions();
    this.props.GetPendingBalance();
    this.props.GetSummaryAdjustment();
  }

  renderWalletSummary() {
    if (
      this.props.wallet.balance.loading === true ||
      this.props.wallet.balance.data === null
    )
      return UserWalletSummaryLoader;
    if (
      this.props.wallet.pairedCurrency.loading === true ||
      this.props.wallet.pairedCurrency.data === null
    )
      return UserWalletSummaryLoader;
    return {
      data: this.props.wallet.balance.data
        // .filter((e) => {
        //   return this.props.wallet.pairedCurrency.data.includes(e.currencyName);
        // })
        .map((e, i) => {
          return {
            srNo: i + 1,
            currency: e.currencyName,
            balance: e.balance,
            inTrade: e.inTrade,
            inWithdrawal: e.inWithdrawal,
            staking_balance: e.staking_balance,
          };
        }),
      customColSpan: false,
    };
  }

  renderOwnedBalance() {
    if (
      this.props.wallet.btcNodeBalance.data === null ||
      this.props.wallet.btcNodeBalance.loading === true
    ) {
      return UserWalletSummaryLoader;
    }

    if (
      this.props.wallet.adminAddressBalance.data === null ||
      this.props.wallet.adminAddressBalance.loading === true
    ) {
      return UserWalletSummaryLoader;
    }

    // if (
    //   this.props.wallet.fineryPositions.loading === true ||
    //   this.props.wallet.fineryPositions.data === null ||
    //   !this.props.wallet.fineryPositions.data[1]
    // )
    //   return UserWalletSummaryLoader;

    if (
      this.props.wallet.b2c2Positions.loading === true ||
      this.props.wallet.b2c2Positions.data === null
    )
      return UserWalletSummaryLoader;

    // const fineryPositions = this.props.wallet.fineryPositions.data[1]
    //   ? this.props.wallet.fineryPositions.data[1].map(([currency, balance]) => {
    //       return {
    //         currency,
    //         balance: parseFloat(balance) / FINERY_PRICE_MULTIPLIER,
    //         platform: "Finery",
    //       };
    //     })
    //   : [];

    const b2c2Positions = this.props.wallet.b2c2Positions.data
      ? this.props.wallet.b2c2Positions.data.map(([currency, balance]) => {
          return {
            currency,
            balance: parseFloat(balance),
            platform: "B2C2",
          };
        })
      : [];


      const adjustments = this.props.wallet.adjustments.data
      ? this.props.wallet.adjustments.data.map(({currency, balance, platform}) => {
          return {
            currency,
            balance: parseFloat(balance),
            platform: platform,
          };
        })
      : [];


    let pendingBalances =
    this.props.wallet.pendingBalances.data &&
    this.props.wallet.pendingBalances.data.data &&
    this.props.wallet.pendingBalances.data.data.map(
      ({ currencyName, balance }) => {
        return {
          currency: currencyName,
          balance: parseFloat(balance),
          platform: "Hot Wallet",
        };
      }
    );

    if (!pendingBalances) pendingBalances=[]


    let data = [];

    data.push({
      currency: "BTC",
      balance: this.props.wallet.btcNodeBalance.data,
      platform: "BTC Node",
    });

    data = [
      ...this.props.wallet.adminAddressBalance.data,
      ...data,
      // ...fineryPositions,
      ...b2c2Positions,
      ...pendingBalances,
      ...adjustments
    ].map((e, i) => {
      return { ...e, currency: e.currency?.toUpperCase(), srNo: i + 1 };
    });

    return { data, customColSpan: false };
  }

  getCompareBalance() {
    if (
      this.props.wallet.btcNodeBalance.data === null ||
      this.props.wallet.btcNodeBalance.loading === true
    ) {
      return [];
    }

    if (
      this.props.wallet.adminAddressBalance.data === null ||
      this.props.wallet.adminAddressBalance.loading === true
    ) {
      return [];
    }
    if (
      this.props.wallet.balance.loading === true ||
      this.props.wallet.balance.data === null
    )
      return [];
    if (
      this.props.wallet.pairedCurrency.loading === true ||
      this.props.wallet.pairedCurrency.data === null
    )
      return [];

    // if (
    //   this.props.wallet.fineryPositions.loading === true ||
    //   this.props.wallet.fineryPositions.data === null ||
    //   !this.props.wallet.fineryPositions.data[1]
    // )
    //   return [];

    if (
      this.props.wallet.b2c2Positions.loading === true ||
      this.props.wallet.b2c2Positions.data === null || 
      this.props.wallet.adjustments.loading===true || 
      this.props.wallet.adjustments.data === null
    )
      return [];

    const userBalance = this.props.wallet.balance.data
      // .filter((e) => {
      //   return this.props.wallet.pairedCurrency.data.includes(e.currencyName);
      // })
      .map((e, i) => {
        return {
          currency: e.currencyName,
          balance:
            parseFloat(e.balance) +
            parseFloat(e.inTrade) +
            parseFloat(e.inWithdrawal) +
            parseFloat(e.staking_balance),
        };
      });

    // const fineryPositions = this.props.wallet.fineryPositions.data[1]
    //   ? this.props.wallet.fineryPositions.data[1].map(([currency, balance]) => {
    //       return {
    //         currency,
    //         balance: parseFloat(balance) / FINERY_PRICE_MULTIPLIER,
    //         platform: "finery",
    //       };
    //     })
    //   : [];

    const b2c2Positions = this.props.wallet.b2c2Positions.data.map(
      ([currency, balance]) => {
        return {
          currency,
          balance: parseFloat(balance),
          platform: "B2C2",
        };
      }
    );

    const adjustments = this.props.wallet.adjustments.data
      ? this.props.wallet.adjustments.data.map(({currency, balance, platform}) => {
          return {
            currency,
            balance: parseFloat(balance),
            platform: platform,
          };
        })
      : [];

    const pendingBalances =
      this.props.wallet.pendingBalances.data &&
      this.props.wallet.pendingBalances.data.data &&
      this.props.wallet.pendingBalances.data.data.map(
        ({ currencyName, balance }) => {
          return {
            currency: currencyName,
            balance: parseFloat(balance),
            platform: "Hot Wallet",
          };
        }
      );

    // let accounted = [...fineryPositions, ...b2c2Positions];
    let accounted = [...b2c2Positions, ...adjustments];

    if (pendingBalances) {
      accounted = [...accounted, ...pendingBalances];
    }

    accounted.push({
      currency: "BTC",
      balance: this.props.wallet.btcNodeBalance.data,
      platform: "BTC Node",
    });

    accounted = [...this.props.wallet.adminAddressBalance.data, ...accounted];

    accounted = accounted.reduce((acc, cur) => {
      if (acc[cur.currency]) acc[cur.currency] += parseFloat(cur.balance);
      else acc[cur.currency] = parseFloat(cur.balance);
      return acc;
    }, {});

    return userBalance
      .filter((e) => CompareCurrencies.includes(e.currency))
      .map((e, i) => {
        return {
          ...e,
          srNo: i + 1,
          accounted: accounted[e.currency] || 0,
        };
      });
  }

  render() {
    const text = this.props.text;

    return (
      <div className="main-panel dashboard">
        <CompareBalance
          data={this.getCompareBalance()}
          tableName="Crypto Positions"
          columns={UserWalletSummaryInteractive}
        />

        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <Table
                columns={UserWalletSummary}
                className="table1"
                tableName={text.totalTotalUser}
                keyField="srNo"
                {...this.renderWalletSummary()}
              />
            </Col>
            <Col lg={6} sm={12}>
              <Table
                columns={AdminAssetSummary}
                className="table1"
                tableName={text.totalBalanceAccounted}
                keyField="srNo"
                {...this.renderOwnedBalance()}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps({ auth, wallet }) {
  return { auth, wallet };
}

export default connect(mapStateToProps, actions)(Dashboard);
