import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm
} from "../../utils/Table";
import { DynamicActionBtn, SelectOption } from "../common/RenderCustomView";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import CreatePaymentCurrencyForm from "./CreatePaymentCurrencyForm";
import { API_MERCHANT_ROOT, NETWORK_CURRENCY_OPTIONS } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const currencyColumns = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyType",
    text: "Currency Type",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "minimumAmount",
    text: "Minimum Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "handlingFeeAmount",
    text: "Handling Fee Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "network",
    text: "Network",
    classes: "u-center",
    formatter: (network) => {
      return network ? network : "No Currency Network"
    },
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },

  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

// const currencyNetworks = [
//   { label: "ETH", value: "ETH" },
//   { label: "BNB", value: "BNB" },
//   { label: "TRX", value: "TRX" },
//   { label: "XDC", value: "XDC" },
// ]

class PaymentCurrency extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: { data: [], loading: false },
      currencyList: []
    };

    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getPaymentCurrency();
    this.getCurrencyList();
  }

  getCurrencyList = () => {
    MerchantAxiosInstance.post("/admin-dashboard-api-service/currency/get-all-currency")
      .then((resp) => {
        if (resp.status === 200 || resp.statusCode === 201) {

          this.setState({ currencyList: resp.data.data.rows });
        } else {
          AddNoti(ParseError(resp), { type: "error" });
        }
      })
      .catch((e) => {
        AddNoti(ParseError(e), { type: "error" });
      });
  };

  getPaymentCurrency() {
    this.generalStateUpdater("currency", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment-currency/get-all-payment-currency`,
      method: "POST",
      postProcessor: (e) => {
        // const addCurrencyNetworkDataTable = [
        //   { name: "uniqueId", value: e.uniqueId, disabled: true },
        //   { name: "network", type: "select", options: [...SelectOption, ...currencyNetworks] }
        // ];

        const Enable = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment-currency/enable-payment-currency`}
            type="actionBtnMerchant"
            options={{
              variant: "warning",
              name: "Enable",
              data: { uniqueId: e.uniqueId },
              className: "mb-1"
            }}
          />
        )

        const Disable = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment-currency/disable-payment-currency`}
            type="actionBtnMerchant"
            options={{
              variant: "danger",
              name: "Disable",
              data: { uniqueId: e.uniqueId },
              className: "mb-1"

            }}
          />
        )
        const AddCurrNetwork = (
          <DynamicActionBtn
            link={`payment-currency-network/add/${e.uniqueId}`}
            type="navigateBtn"
            options={{
              variant: "info",
              name: "Add Currency Network",
              data: e,
              className: "mb-1"

            }}

          />
        )
        const RemoveCurrNetwork = (
          <DynamicActionBtn
            link={`payment-currency-network/remove/${e.uniqueId}`}
            type="navigateBtn"
            options={{
              variant: "danger",
              name: "Remove Currency Network",
              data: e,
              className: "mb-1"

            }}
          />
        )

        return {
          ...e,
          actions: [
            {
              modalName: "Payment Currency",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Enable,
            Disable,
            AddCurrNetwork,
            RemoveCurrNetwork
          ],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("currencyName");
    return {
      data: this.state[name].data.map((e, i) => {
        if (e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }


  createPaymentCurrency() {
    let currencyMap = [{ label: "loading", value: "" }];
    if (this.state.currencyList.length > 0) {
      currencyMap = this.state.currencyList.map((currency) => {
        return { label: currency.symbol, value: `${currency.uniqueId}|${currency.symbol}` };
      });
    }
    const data = [
      { name: "currencyId", type: "react-select", options: [...SelectOption, ...currencyMap] },
      { name: "currencyName" },
      {
        name: "currencyType", type: "select", options: [
          { label: "Wallet", value: "wallet" },
          { label: "Blockchain", value: "blockchain" },
        ]
      },
      {
        name: "status", type: "select", options: [
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ]
      },
      {
        name: "network", type: "select", options: [...SelectOption, ...NETWORK_CURRENCY_OPTIONS]
      },
    ];
    const api = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment-currency/create-new-payment-currency`;
    const renderCreateBtnConfigs = { ...RenderCreateBtn(data, api, "Create category") };
    renderCreateBtnConfigs.children = <CreatePaymentCurrencyForm isUserApi={false} data={data} api={api} type="create" />;
    return renderCreateBtnConfigs;
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Payment Currency
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={currencyColumns}
                  className="table1"
                  tableName="Payment Currency"
                  keyField="srNo"
                  createBtn={<ToggleModal {...this.createPaymentCurrency()} />}
                  onRefresh={this.getPaymentCurrency.bind(this)}
                  {...this.renderTableData("currency")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default PaymentCurrency;
