/**
 *
 * !NOTE: refactor to use `Context` rather than Props for sidebar status
 *
 */

import React, { useContext, useState } from "react";
import { Accordion, useAccordionToggle, Dropdown } from "react-bootstrap";
import AccordionContext from "./Context";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CustomToggle({ eventKey, icon, text }) {
  const toggleAccordion = useAccordionToggle(eventKey);

  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  let className = "custom-sidebar__element";

  if (isCurrentEventKey === true) {
    className += " active-opt";
  }

  return (
    <div
      className={className}
      onClick={() => {
        toggleAccordion();
      }}
    >
      <div className="custom-sidebar__element--icon">
        <FontAwesomeIcon className="fa-lg" icon={icon} />
      </div>
      <div className="custom-sidebar__element--text">{text}</div>
    </div>
  );
}

export function AccordionElement({
  eventKey,
  children,
  icon,
  text,
  toggle,
  list,
  sidebarCollapsed,
}) {
  const [menuOpen, toggleMenuOpen] = useState(false);
  let history = useHistory();

  let className = "custom-sidebar__element";

  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  if (isCurrentEventKey === true) {
    className += " active-opt";
  }

  const renderedList = (
    <ol>
      {list.map((e, i) => (
        <li key={i}>
          <Link
            key={i}
            onClick={() => {
              toggle(eventKey);
            }}
            to={e.to}
          >
            {e.text}
          </Link>
        </li>
      ))}
    </ol>
  );

  const renderedListDropdown = list.map((e, i) => (
    <Dropdown.Item
      as={Link}
      key={i}
      to={e.to}
      onMouseDown={() => {
        toggle(eventKey);
        history.push(e.to);
      }}
    >
      <li key={i}>
        <div className="custom-sidebar__element--text">{e.text}</div>
      </li>
    </Dropdown.Item>
  ));

  if (sidebarCollapsed === false)
    return (
      <div>
        <CustomToggle
          eventKey={eventKey}
          icon={icon}
          text={text}
          toggle={toggle}
        />
        <Accordion.Collapse eventKey={eventKey} className="accordion-body">
          {renderedList}
        </Accordion.Collapse>
      </div>
    );
  else {
    return (
      <Dropdown
        onFocus={() => {
          toggleMenuOpen(true);
        }}
        onBlur={() => {
          toggleMenuOpen(false);
        }}
        show={menuOpen}
        drop={"right"}
      >
        <Dropdown.Toggle as={DropdownToggle}>
          <div className={className}>
            <div className="custom-sidebar__element--icon">
              <FontAwesomeIcon className="fa-lg" icon={icon} />
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <ol>{renderedListDropdown}</ol>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const DropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export function NavElement({
  eventKey,
  icon,
  text,
  toggle,
  sidebarCollapsed,
  to,
  isLink
}) {
  const toggleAccordion = useAccordionToggle("");
  const currentEventKey = useContext(AccordionContext);
  const [menuOpen, toggleMenuOpen] = useState(false);

  let history = useHistory();

  const isCurrentEventKey = currentEventKey === eventKey;

  let className = "custom-sidebar__element";

  if (isCurrentEventKey === true) {
    className += " active-opt";
  }

  let component;
  if(isLink) {
    component = (
      <Link to={to}>
        <div
          className={className}
          onClick={() => {
            toggleAccordion();
            toggle(eventKey);
          }}
        >
          <div className="custom-sidebar__element--icon">
            <FontAwesomeIcon className="fa-lg" icon={icon} />
          </div>
          <div className="custom-sidebar__element--text">{text}</div>
        </div>
      </Link>
    );
  
    if (sidebarCollapsed === true) {
      component = (
        <Dropdown
          onFocus={() => {
            toggleMenuOpen(true);
          }}
          onBlur={() => {
            toggleMenuOpen(false);
          }}
          show={menuOpen}
          drop={"right"}
        >
          <Dropdown.Toggle as={DropdownToggle}>
            <div className={className}>
              <div className="custom-sidebar__element--icon">
                <FontAwesomeIcon className="fa-lg" icon={icon} />
              </div>
            </div>
          </Dropdown.Toggle>
  
          <Dropdown.Menu>
            <Dropdown.Item>
              {
                <ol>
                  <li>
                    <Link to="">
                      <div
                        onMouseDown={() => {
                          toggleAccordion();
                          toggle(eventKey);
                          history.push(to);
                        }}
                        className="custom-sidebar__element--text"
                      >
                        {text}
                      </div>
                    </Link>
                  </li>
                </ol>
              }
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  } else {
    component = (
      <div>
        <div
          className={`${className} custom-sidebar__element--inactive`}
        >
          <div className="custom-sidebar__element--text">{text}</div>
        </div>
      </div>
    );

  }

  return component;
}
