import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import { DynamicActionBtn,DynamicForm } from "../common/RenderCustomView";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const merchantRefundRequestDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "transactionType",
    text: "Transaction Type",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "totalRefundAmount",
    text: "Total Refund Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  {
    dataField: "refundFeesGlobiance",
    text: "Refund Fees Globiance",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  {
    dataField: "refundCreditMerchant",
    text: "Refund Credit Merchant",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  {
    dataField: "refundAmountSent",
    text: "Refund Amount Sent",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  {
    dataField: "refundStatus",
    text: "Refund Status",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  }, 
  {
    dataField: "cancelReason",
    text: "Cancel Reason",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MerchantRefundRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refundRequest: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
      searchParams: { to: "", from: "", refundStatus: "" },
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };
    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
  
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;
  
    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00'; // Set "from" date to YYYY-MM-DD 00:00:00 format

    this.setState({ searchParams },()=> this.getRefundMerchantRequests(0));
  }

  handleChange = ({ currentTarget: input }) => { 
    // console.log(input);
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getRefundMerchantRequests(page);
  }

  handleSearch = () => {
    this.getRefundMerchantRequests(0);
  }

  getRefundMerchantRequests(page) {
    const {to, from, refundStatus} = this.state.searchParams;
    let params = {
      "page": page,
      "limit": this.state.pageSize
    };
      if(to != '') { params.to = moment(to).toISOString(); }
      if(from != '') {  params.from = moment(from).toISOString(); }
      if(refundStatus != '') { params.refundStatus = refundStatus;}

    this.generalStateUpdater("refundRequest", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/refund/get-all-refund`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const confirmRefundRequestApi =
          `${API_MERCHANT_ROOT}/admin-dashboard-api-service/refund/confirm-refund`;
        const confirmRefundRequest = [
          { name: "refundId", value: e.uniqueId, disabled: true },

          { name: "txHash", defaultValue: "" },
          { name: "confirmations",type:"number", defaultValue: "" },
          { name: "confirmationRequired",type:"number", defaultValue: "" },
          { name: "confirmedTime", type:"datetime" , defaultValue: "" },
          { name: "transactionTime", type:"datetime",  defaultValue: "" }
        ];

        const rejectRefundRequestApi =
          `${API_MERCHANT_ROOT}/admin-dashboard-api-service/refund/reject-refund`;
        const rejectRefundRequest = [
          { name: "refundId", value: e.uniqueId, disabled: true },

          { name: "cancelReason", defaultValue: "" }
        ];

        const View = (
          {
            modalName: "View Refund Request",
            children: <ViewOnlyForm data={e} />,
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
          }
        )

        const Confirm = (
          {
            modalName: "Confirm Refund Request",
            children: (
              <DynamicForm
                data={confirmRefundRequest}
                api={confirmRefundRequestApi}
              />
            ),
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
            btnName: "Confirm",
            btnVariant: "warning",
          }
        );
        

        const Reject = (
          {
            modalName: "Reject Refund Request",
            children: (
              <DynamicForm
                data={rejectRefundRequest}
                api={rejectRefundRequestApi}
              />
            ),
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
            btnName: "Reject",
            btnVariant: "danger",
          }
        );

        return {
          ...e,
          actions: [
            View,
            Confirm,
            Reject
          ],
        };
      },
    });
  }

  renderTableData(name) {
    const { currentPage, pageSize } =  this.state;
    let srNo = currentPage * pageSize;
    if (this.state[name].loading === true) return TableLoader("transactionType");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if(e.totalRefundAmount) {
          e.totalRefundAmount = e.totalRefundAmount.toLocaleString();
        }
        return {
          srNo: i + 1 + srNo,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
     
        <Card className="custom-card-1">
          <div className="title">
            Merchant Refund Requests
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
          <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                  <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                    
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Refund Status:</Form.Label>
                        <select className="form-control" name="refundStatus" onChange={this.handleChange}>
                          <option value="">select</option>
                          <option value="pending">Pending</option>
                          <option value="error">Error</option>
                          <option value="completed">Completed</option>
                        </select>
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantRefundRequestDataColumn}
                  className="table1"
                  tableName="Refund Requests"
                  keyField="srNo"
                  customPagination="custom"
                  totalCount={this.state.refundRequest.totalRecords}
                  pageSize={this.state.pageSize}
                  pageNumber={this.state.currentPage}
                  onPageChange={this.handlePage}      
                  
                  {...this.renderTableData("refundRequest")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantRefundRequests;
