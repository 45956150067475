import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const APP_NAME = "MerchantAdmin";
export const API_MERCHANT_ROOT = process.env.REACT_APP_API_MERCHANT_ROOT;
export const ENV = process.env.REACT_APP_ENV;
export const AUTH_STATUS_PATH = `${APP_NAME}-AUTH-STATUS`;
export const AUTH_TOKEN_PATH = `${APP_NAME}-AUTH-TOKEN`;
export const AUTH_ADMIN_LOG_OUT_STATUS = `${APP_NAME}-AUTH-LOG-OUT-STATUS`;

export const toastTime = 3000;

export const GLOBAL = "global";

export const ColorLightGreen = "#8ce78c";
export const ColorLightRed = "#f78080";
export const ColorLightGrey = "lightgrey";
export const ColorLightYellow = "#ffc1075c";

export const NETWORK_CURRENCY_OPTIONS = [
  { label: "BTC", value: "BTC" },
  { label: "BTC-LND", value: "BTC-LND" },
	{ label: "XDC", value: "XDC" },
	{ label: "ETH", value: "ETH" },
	{ label: "TRX", value: "TRX" },
	{ label: "POLYGON", value: "POLYGON" },
	{ label: "XRP", value: "XRP" },
	{ label: "BNB", value: "BNB" },
]

export const CompareCurrencies = [
  "BTC",
  "ETH",
  "XRP",
  "EUR",
  "XDC",
  "PLI",
  "LGCY",
  "SRX",
  "GBEX",
  "USDT",
  "USDC",
  "DC",
  "GZX"
];

export const FINERY_PRICE_MULTIPLIER = 10 ** 8;

export const ActionButtonType = {
  modal: "MODAL",
  actionButton: "ACTION_BUTTON",
  actionButtonDownload: "ACTION_BUTTON_DOWNLOAD",
  commonActionButton: "COMMON_ACTION_BUTTON",
  redirectButton: "REDIRECTION_BUTTON",
};

export const PairDecimals = {
  BTCEUR: {
    price: 0,
    amount: 6,
    total: 0,
  },
  ETHEUR: {
    price: 1,
    amount: 2,
    total: 0,
  },
  ETHBTC: {
    price: 5,
    amount: 2,
    total: 5,
  },

  // BTCSGD: {
  //   price: 0,
  //   amount: 6,
  //   total: 0,
  // },
  // ETHSGD: {
  //   price: 1,
  //   amount: 2,
  //   total: 0,
  // },

  XRPEUR: {
    price: 3,
    amount: 2,
    total: 2,
  },
  DOTEUR: {
    price: 5,
    amount: 2,
    total: 2,
  },
  BNBEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  ADAEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  LNKEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  UNIEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  EOSEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
  XLMEUR: {
    price: 2,
    amount: 4,
    total: 2,
  },
};

export const AggregatedExchanges = ["bitfinex", "finery", "b2c2", "bitcashier"];

export const SystemAccounts = ["swapuser@globiance.com", "mm1@globiance.com", "dev@globiance.com", "appdev@globiance.com", "bvimm@globiance.com", "profit@globiance.com"];

export const ButtonVariant = [
  "info",
  "warning",
  "danger",
  "success",
  "primary",
  "seconday",
  "light",
  "dark",
];

export const Pairs = [
  "BTCEUR",
  "ETHEUR",
  "ETHBTC",
  "BTCSGD",
  "ETHSGD",

  "XRPEUR",
  "BNBEUR",
  "ADAEUR",
  "DOTEUR",
  "LNKEUR",
  "UNIEUR",
  "EOSEUR",
  "XLMEUR",
];

export const ANALYSIS_TYPE = {
  support: "SUPPORT",
  liquidate: "LIQUIDATE",
};

Object.defineProperty(Object.prototype, "partialMatch", {
  value: function (fields) {
    for (let key of Object.keys(fields)) {
      if (Object.keys(this).includes(key)) {
        if (this[key] === fields[key]) continue;
        return false;
      } else {
        return false;
      }
    }
    return true;
  },
});

Object.defineProperty(Array.prototype, "includesPartial", {
  value: function (fields) {
    for (let i = 0; i < this.length; i++) {
      const obj = this[i];
      if (obj.partialMatch(fields)) {
        return i;
      }
    }
    return null;
  },
});

Object.defineProperty(Array.prototype, "includesPartialAll", {
  value: function (fields) {
    const retIndex = [];
    for (let i = 0; i < this.length; i++) {
      const obj = this[i];
      if (obj.partialMatch(fields)) {
        retIndex.push(i);
      }
    }
    if (retIndex.length === 0) return null;
    return retIndex;
  },
});

export const EmptyData = [
  {
    id: 1,
    maxVolume: (
      <div className="table-one__loader">
        <div>No Data</div>
      </div>
    ),
  },
];

export const prepopulatedData = [
  {
    id: 1,
    tradeFee: (
      <div className="table-one__loader">
        <FontAwesomeIcon icon={faCogs} size="5x" />
        <div>LOADING</div>
      </div>
    ),
  },
];

