import { AUTH_ADMIN_LOG_OUT_STATUS, AUTH_STATUS_PATH, AUTH_TOKEN_PATH, toastTime } from "../../helper/constant";
import * as types from "../actions/actionTypes";
import { toast } from "react-toastify";

const initialState = {
  isLoggedIn: !!localStorage.getItem(AUTH_TOKEN_PATH),
  admin: null,
  token: null,
  hasLoggedOut: false
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_DATA: {
      if(action && action.admin && Object.keys(action.admin).length > 0) {
        return {
            ...state,
            admin: action.admin,
            isLoggedIn: true,
        };
    } else {
        localStorage.removeItem(AUTH_TOKEN_PATH);

        toast.error('The session has expired. Please login.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          })
        return {
            ...state,
            isLoggedIn: false,
        };
    }
      // return { ...state, admin: action.admin };
    }

    case types.LOGIN_SUCCESS: {
      // ReconnectSocket();
      localStorage.setItem(AUTH_TOKEN_PATH, action.payload);
      localStorage.setItem(AUTH_ADMIN_LOG_OUT_STATUS, false);
      return { ...state, isLoggedIn: true, token: action.payload, hasLoggedOut: false };
    }

    case types.LOGIN_FAIL: {
      localStorage.removeItem(AUTH_STATUS_PATH);
      localStorage.removeItem(AUTH_TOKEN_PATH);
      return { ...state, isLoggedIn: false, token: null  };
    }

    case types.LOGOUT: {
      localStorage.removeItem(AUTH_STATUS_PATH);
      localStorage.removeItem(AUTH_TOKEN_PATH);
      localStorage.setItem(AUTH_ADMIN_LOG_OUT_STATUS, true);
      return { ...state, isLoggedIn: false, token: null, hasLoggedOut: true };
    }

    default:
      return state;
  }
};

export default LoginReducer;
