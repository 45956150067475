import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  IconHeader,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { DynamicActionBtn, DynamicForm } from "../common/RenderCustomView";

import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const MerchantUsersDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    classes: "highlight",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "firstName",
    text: "First Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "lastName",
    text: "Last Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

export class MerchantUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantUsers: { data: [], loading: false, totalCount: 0 },
      currencyList: []
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantUsers();
  }

  getMerchantUsers() {
    const enableAPI = `/admin-dashboard-api-service/merchant/enable-merchant`;
    const disableAPI = `/admin-dashboard-api-service/merchant/disable-merchant`;
    const resetPassAPI = `/admin-dashboard-api-service/merchant/reset-password`;
    this.generalStateUpdater("merchantUsers", {
      path: `admin-dashboard-api-service/merchant/get-all-merchant`,
      method: "POST",
      postProcessor: (e) => {

        const resetPassUpdateData = [
          { name: "merchantId", value: e.uniqueId, disabled: true },
          { name: "password" },
          { name: "confirmPassword"},
        ];
        const Enable = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={enableAPI}
            options={{
              variant: "success",
              name: "Enable",
              data: {
                merchantId: e.uniqueId,
              },
            }}
          />
        );

        const Disable = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={disableAPI}
            options={{
              variant: "danger",
              name: "Disable",
              data: {
                merchantId: e.uniqueId,
              },
            }}
          />
        );

        const { password = "", ...rest } = e;

        return {
          ...rest,
          actions: [
            {
              modalName: "View Merchant User",
              children: <ViewOnlyForm data={rest} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Enable,
            Disable,
            {
              modalName: `Reset Password : ${e.firstName} ${e.lastName}`,
              children: <DynamicForm data={resetPassUpdateData} api={resetPassAPI} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
              btnName: "Reset Password",
              btnVariant: "warning",
            }
          ],
        };
      },
    });
  }

  createMerchantUser() {
    const data = [
      { name: "firstName", type: "text", placeholder: "Please Enter First Name" },
      { name: "lastName", type: "text", placeholder: "Please Enter Last Name" },
      { name: "email", type: "text", placeholder: "Please Enter Email" },
      { name: "password", type: "text", placeholder: "Please Enter Password" },
      { name: "confirmPassword", type: "text", placeholder: "Please Enter Confirm Password" },
    ];

    const api = `/admin-dashboard-api-service/merchant/create-merchant`;
    return RenderCreateBtn(data, api, "Create Merchant User");
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("email");
    return {
      data: this.state[name].data
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if(e.updatedAt != null) {
          e.updatedAt = moment(e.updatedAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }


  render() {
    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Users
            <div className="title--bottom"></div>
          </div>

          <div className="card-body">
            
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={MerchantUsersDataColumn}
                  className="table1"
                  tableName="Merchant User"
                  keyField="srNo"
                  createBtn={<ToggleModal {...this.createMerchantUser()} />}
                  onRefresh={this.getMerchantUsers.bind(this)}
                  {...this.renderTableData("merchantUsers")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantUsers;
