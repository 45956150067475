import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "../common/Table";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  TableLoader,
} from "../../utils/Table";
import moment from "moment";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const quotesColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "merchantUserId",
    text: "Merchant User Id",
    classes: "highlight",
    sort: true,
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "checkoutId",
    text: "Checkout Id",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyId",
    text: "Currency Id",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "promisedSettlementRateUser",
    text: "Promised Settlement Rate User",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "promisedSettlementRateMerchant",
    text: "Promised Settlement Rate Merchant",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "promisedSettlementRateGlobiance",
    text: "Promised Settlement Rate Globiance",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "priceUser",
    text: "Price User",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "priceMerchant",
    text: "Price Merchant",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "priceGlobiance",
    text: "Price Globiance",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amountUsd",
    text: "Amount Usd",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amountGlobiance",
    text: "Amount Globiance",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "networkSurchargeAmount",
    text: "Network Surcharge Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "networkSurchargeAmountUsd",
    text: "Network Surcharge Amount Usd",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amountMerchant",
    text: "Amount Merchant",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amountUser",
    text: "Amount User",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "GHFP",
    text: "GHFP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "GOFP",
    text: "GOFP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "MOFP",
    text: "MOFP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "MHFP",
    text: "MHFP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "gafOneP",
    text: "gafOneP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "gafTwoP",
    text: "gafTwoP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "mafOneP",
    text: "mafOneP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "mafTwoP",
    text: "mafTwoP",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },  
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },  
  {
    dataField: "updatedAt",
    text: "Updated At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  } 
];

class QuotesTable extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      quotes: { data: this.props.quotes, loading: false },
    };
  }
  
  renderTableData(name) { 
    if (this.state.quotes.loading === true) return TableLoader("merchantUserId");
    return {
      data: this.props.quotes && this.props.quotes.length > 0 && this.props.quotes.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD H:mm:ss');
        }
        if(e.updateddAt != null) {
          e.updateddAt = moment(e.updateddAt).format('YYYY-MM-DD H:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
          actions: [],
        };
      }),
    };
  }

  render() {
    return (
      <Row style={{ marginTop: "20px"}}>
        <Col lg={12} sm={12} md={12}>
          <Table
            columns={quotesColumn}
            className="table1"
            tableName="Quotes"
            keyField="srNo"
            {...this.renderTableData("quotes")}
          />
        </Col>
      </Row>
    );
  }
}

export default QuotesTable;
