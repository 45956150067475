import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";

import {
  faTachometerAlt,
  faUsers,
  faMoneyBillWave,
  faExchangeAlt,
  faUndoAlt,
  faFileInvoiceDollar,
  faKey,
  faShoppingCart,
  faCreditCard,
  faFileContract,
  faHandHoldingUsd,
  faBalanceScale,
  faChartPie,
  faCashRegister,
  faServer,
  faClipboardList,
  faCoins,
  faWallet,
  faColumns,
  faHeadset,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import { AccordionElement, NavElement } from "./CustomElement";
import AccordionContext from "./Context";

import { HasAccess } from "../../../helper/AccessHelper";



const accordionMerchantAdmin = [
  { text: "Global Configuration", isLink: false },
  { text: "Merchant Currency", to: "/merchant-currency", icon: faCoins, isLink: true },
  { text: "Payment Gateway Currency", to: "/payment-gateway-currency", icon: faMoneyBillWave },
  { text: "Settlement Pair", to: "/settlement-pair", icon: faExchangeAlt },
  { text: "Refund Fee", to: "/merchant-admin-refund-fee", icon: faUndoAlt },
  { text: "Page Layouts", to: "/merchant-page-layout", icon: faColumns },
  { text: "Support", to: "/merchant-support-tickets", icon: faHeadset },
  { text: "Blockprocessor", to: "/merchant-blockprocessor", icon: faServer },
  { text: "InstaXchange", to: "/merchant-instaxchange", icon: faTimes },

  { text: "Fees", isLink: false },
  { text: "Fee Distribution", to: "/merchant-admin-fee-distribution", icon: faHandHoldingUsd },
  { text: "Merchant Balances Tally", to: "/merchant-balances-tally", icon: faChartPie },
  { text: "Globiance Fee Collection", to: "/globiance-fee-collection", icon: faCashRegister },

  { text: "Merchant Config", isLink: false },
  { text: "Merchants", to: "/merchant-users", icon: faUsers },
  { text: "Merchant Keys", to: "/merchant-admin-keys", icon: faKey },
  { text: "Merchant Checkouts", to: "/merchant-admin-checkout", icon: faShoppingCart },
  { text: "Merchant Payments", to: "/merchant-admin-payment", icon: faCreditCard },
  { text: "Merchant Withdrawal", to: "/merchant-withdrawal", icon: faWallet },
  { text: "Merchant Refund Requests", to: "/merchant-admin-refund-request", icon: faFileInvoiceDollar },
  { text: "Merchant Balances", to: "/merchant-admin-balances", icon: faBalanceScale },
  { text: "Merchant Settlement Order", to: "/merchant-admin-settlement-order", icon: faFileContract },

  { text: "Dev", isLink: false },
  { text: "LoggerUI", to: "/merchant-loggerui", icon: faClipboardList },
];

function renderSidebarElement(data, roleAccess) {
  return data.map((e, i) => {
    const access = HasAccess(roleAccess, e.access);
    if (access.hasAccess === false) return null;
    if (e.hasOwnProperty("list")) {
      return (
        <AccordionElement
          key={i}
          icon={e.icon}
          text={e.text}
          eventKey={i}
          toggle={e.toggle}
          list={e.list}
          sidebarCollapsed={e.sidebarCollapsed}
        />
      );
    } else if(e.isLink === false){
      return (
        <NavElement
          key={i}
          text={e.text}
          eventKey={i}
          sidebarCollapsed={e.sidebarCollapsed}
          isLink={e.isLink}
        />
      );
    } else {
      return (
        <NavElement
          key={i}
          icon={e.icon}
          text={e.text}
          eventKey={i}
          toggle={e.toggle}
          sidebarCollapsed={e.sidebarCollapsed}
          to={e.to}
          isLink={e.isLink}
        />
      );
    }
  });
}

function Sidebar(props) {
  let sidebarClass = "custom-sidebar";

  if (props.sidebarCollapsed === true) {
    sidebarClass += " custom-sidebar__collapsed";
  }

  const [currActive, setcurrActive] = useState("");

  const data = [
    {
      icon: faTachometerAlt,
      text: "Dashboard",
      toggle: setcurrActive,
      sidebarCollapsed: props.sidebarCollapsed,
      to: "/",
      access: [],
      isLink: true
    },
    ...getMerchantRoutes()

  ];

  function getMerchantRoutes () {
    return accordionMerchantAdmin.map(({ text, to, icon, isLink = true }) => {

      if(isLink) {
        return {
          text,
          toggle: setcurrActive,
          sidebarCollapsed: props.sidebarCollapsed,
          to,
          access: [],
          icon,
          isLink
        }
      } else {
        return {
          text,
          sidebarCollapsed: props.sidebarCollapsed,
          access: [],
          isLink
        }
      }
    })
  }

  useEffect(() => {
    const href = window.document.location.pathname;
    let hrefIndex = data.includesPartial({ to: href });
    if (hrefIndex === null) {
      hrefIndex = data.reduce((acc, curr, i) => {
        if (curr.list) {
          if (curr.list.includesPartial({ to: href }) !== null) acc = i;
        }
        return acc;
      }, null);
    }
    if (hrefIndex !== null) setcurrActive(hrefIndex);
  }, []);

  return (
    <div className={sidebarClass}>
      <AccordionContext.Provider value={currActive}>
        {/* <Accordion>{renderSidebarElement(data, props.access)}</Accordion> */}
        <Accordion>{renderSidebarElement(data, true)}</Accordion>
      </AccordionContext.Provider>
    </div>
  );
}

export default Sidebar;
