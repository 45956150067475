import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { AddNoti } from "../../helper/Notification";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { ParseError } from "../../helper/ResponseHelper";
import { SelectOption } from "../common/RenderCustomView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ViewOnlyForm } from "../../utils/Table";
import { NETWORK_CURRENCY_OPTIONS } from "../../helper/constant";

const PaymentCurrencyNetwork = () => {

	const [form, setForm] = useState({
		uniqueId: "",
		network: ""
	});

	const [currencyList, setCurrencyList] = useState([...NETWORK_CURRENCY_OPTIONS]);

	const params = useParams();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (params && params.id) {
			const nextFormState = {
				...form,
				uniqueId: params.id
			};
			setForm(nextFormState);
		}

	}, [params.action, params.id]);

	useEffect(() => {
		if (location && location.state) {
			let networks = location.state.data.network;
			if (networks) {
				networks = networks.split(",")
				let currencyListMap = [...currencyList];
				currencyListMap = currencyListMap.filter((item) => {
					if (item.hidden) {
						return item
					} else {
						if (params.action === "add") {
							if (!networks.includes(item.value)) {
								return item
							}
						} else {
							if (networks.includes(item.value)) {
								return item
							}
						}
					}
				})
				currencyListMap = currencyListMap.length > 1 ? currencyListMap : [{ label: "No data", value: null, hidden: true }];
				setCurrencyList(currencyListMap);
			} else {
				if (params.action === "remove") {
					setCurrencyList([{ label: "No data", value: null, hidden: true }]);
				}
			}
			const nextFormState = {
				...form,
				uniqueId: params.id,
			};
			setForm(nextFormState);
		}

	}, [location.state]);

	const onUpdateField = (e) => {
		const nextFormState = {
			...form,
			[e.target.name]: e.target.value,
		};
		setForm(nextFormState);
	};

	const PostApi = (path, data, cb) => {
		MerchantAxiosInstance.post(path, data)
			.then((resp) => {
				if (resp.status === 200 || resp.status === 201) {
					AddNoti(resp.data.message, { type: "info" });
				} else {
					AddNoti(ParseError(resp), { type: "error" });
				}
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.message) {
					AddNoti(ParseError(error.response.data.message), { type: "error" });
				} else if (error.response && error.response.data.error && error.response.data.error) {
					AddNoti(ParseError(error.response.data.error), { type: "error" });
				}
				else {
					AddNoti(ParseError(error), { type: "error" });
				}
			})
			.finally(() => {
				cb();
			});
	};

	const _postApi = PostApi;

	const onSubmitForm = (e) => {
		e.preventDefault();

		if (!form.network) {
			toast.error("No currency network selected");
			return 0;
		}
		const api_path = params.action === "add" ?
			"/admin-dashboard-api-service/payment-currency/add-currency-network" :
			"/admin-dashboard-api-service/payment-currency/remove-currency-network";

		_postApi(
			api_path,
			JSON.stringify(form),
			() => {
				// setLoading(false);
			}
		);
	};

	// const handleOptionChange = (index, value) => {
	// 	const updatedNetwork = [...form.network];
	// 	updatedNetwork[index].selectedOption = value;
	// 	const nextFormState = {
	// 		...form,
	// 		network: updatedNetwork
	// 	};
	// 	setForm(nextFormState);
	// };

	// const addNetworkCurrency = () => {
	// 	const nextFormState = {
	// 		...form,
	// 		network: [...form.network, { selectedOption: '' }],
	// 	};
	// 	setForm(nextFormState);
	// };

	// const removeNetworkCurrency = (index) => {
	// 	const updatedNetwork = [...form.network];
	// 	updatedNetwork.splice(index, 1);

	// 	const nextFormState = {
	// 		...form,
	// 		network: updatedNetwork
	// 	};
	// 	setForm(nextFormState);
	// };

	// const { network } = form;

	return (
		<div className="kyb main-panel">
			<form onSubmit={onSubmitForm}>
				<Card className="custom-card-1">
					<div className="d-flex justify-content-between align-items-center">
						<div className="title">
							{params.action === "add" ? 'Add Currency Network' : 'Remove Currency Network'}
							<div className="title--bottom"></div>
						</div>
						<div style={{ margin: "1rem 1rem 0" }} className="mr-4">
							<Button
								onClick={() => history.push("/payment-gateway-currency")}
								variant="primary"
							>
								<FontAwesomeIcon icon={faArrowLeft} /> Back
							</Button>
						</div>
					</div>
					<div className="card-body">
						<Row>
							<Col lg={8} sm={8} md={8}>
								{/* <Row style={{ marginBottom: "20px" }}>
									<Col lg={4} md={4} sm={12}>
										<label>Unique Id</label>
									</Col>
									<Col lg={8} md={8} sm={12}>
										<input
											id="uniqueId"
											name="uniqueId"
											value={form.uniqueId}
											// onChange={onUpdateField}
											className="form-control render-field"
											disabled
											type="text"
										/>
									</Col>
								</Row> */}

								<Row style={{ marginBottom: "20px" }}>
									<Col lg={4} md={4} sm={12}>
										<label>Select Currency Network</label>
									</Col>
									<Col lg={4} md={4} sm={12}>
										<select name="network" id="network"
											className="form-control"
											onChange={onUpdateField}
										// value={optElement.selectedOption}
										// onChange={(e) => handleOptionChange(idx, e.target.value)}
										>
											<option value="">Select</option>
											{currencyList.map(({ label, value, ...rest }, i) => (
												<option
													key={i + 1}
													value={value}
													// selected={optElement.selectedOption === value}
													{...rest}
												>
													{label}
												</option>
											))}
										</select>
									</Col>

								</Row>

								{/* {
									network.length > 0 && network.map((optElement, idx) => (
										<Row style={{ marginBottom: "20px" }}>
											<Col lg={4} md={4} sm={12}>
												<label>Select Currency</label>
											</Col>
											<Col lg={4} md={4} sm={12}>
												<select name="network" id="network"
													className="form-control"
													// onChange={onUpdateField}
													value={optElement.selectedOption}
													onChange={(e) => handleOptionChange(idx, e.target.value)}
												>
													{currencyList.map(({ label, value, ...rest }, i) => (
														<option
															key={i + 1}
															value={value}
															// selected={optElement.selectedOption === value}
															{...rest}
														>
															{label}
														</option>
													))}
												</select>
											</Col>
											{idx > 0 && (
												<Col lg={2} md={2} sm={12}>
													<button
														className="btn-primary btn btn-sm"
														type="button"
														onClick={() => removeNetworkCurrency(idx)}
													>
														Remove
													</button>
												</Col>
											)}
											{(idx === network.length - 1) && (
												<Col lg={2} md={2} sm={12}>
													<button
														className="btn-primary btn btn-sm"
														type="button"
														onClick={addNetworkCurrency}
													>
														Add
													</button>
												</Col>
											)}
										</Row>
									))
								} */}

								<Row style={{ marginBottom: "20px" }}>
									<Col lg={6} md={6} sm={12}>
									</Col>
									<Col lg={2} md={2} sm={12} className="d-flex justify-content-end align-items-center">
										<button type="button" onClick={() => history.push("/payment-gateway-currency")} className="btn-warning btn btn-sm mt-2 mr-3">
											Cancel
										</button>
										<button className="btn-primary btn btn-sm mt-2">
											{params.action === "add" ? "Add" : "Remove"}
										</button>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Card>
			</form>

			<Card className="custom-card-1 mt-3">
				<div className="d-flex justify-content-between align-items-center">
					<div className="title">
						Payment Currency: Details
						<div className="title--bottom"></div>
					</div>
				</div>
				<div className="card-body">
					<Row>
						<Col lg={12} sm={12} md={12}>
							<ViewOnlyForm data={location.state.data} />
						</Col>
					</Row>
				</div>
			</Card>
		</div>
	);
};

export default PaymentCurrencyNetwork;
