import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { DynamicActionBtn } from "../common/RenderCustomView";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";
import CreateMerchantCurrency from "./CreateMerchantCurrency";

const merchantCurrencyDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "symbol",
    text: "Symbol",
    sort: true,
    classes: "highlight",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "image",
    text: "Image",
    formatter: (text) => text ? text : "--" 
  },

  {
    dataField: "createdAt",
    text: "Created At",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

export class MerchantCurrency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantCurrencies: { data: [], loading: false, totalCount: 0 },
      currencyList: []
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantCurrencies();
  }

  getMerchantCurrencies() {
    const deleteAPI = `/admin-dashboard-api-service/currency/delete-currency`;
    this.generalStateUpdater("merchantCurrencies", {
      path: `admin-dashboard-api-service/currency/get-all-currency`,
      method: "POST",
      postProcessor: (e) => {
        const Delete = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={deleteAPI}
            options={{
              variant: "danger",
              name: "Delete",
              data: {
                currencyId: e.uniqueId,
              },
            }}
          />
        );

        return {
          ...e,
          actions: [
            {
              modalName: "View Merchant Currency",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Delete
          ],
        };
      },
    });
  }

  createMerchantCurrency() {
    const data = [
      { name: "name", type: "text", placeholder: "Please Enter Name" },
      { name: "symbol", type: "text", placeholder: "Please Enter Symbol" },
      { name : "image", type : "file", accept: ".png" },
      // { name : "image", type : "file", accept: "image/jpg, image/jpeg, image/png" },
    ];
    const api = `/admin-dashboard-api-service/currency/create-currency`;
    const renderCreateBtnConfigs = { ...RenderCreateBtn(data, api, "Create Merchant Currency") };
    renderCreateBtnConfigs.children = <CreateMerchantCurrency isUserApi={false} data={data} api={api}/>;
    return renderCreateBtnConfigs;

  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("name");
    return {
      data: this.state[name].data
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if(e.updatedAt != null) {
          e.updatedAt = moment(e.updatedAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }


  render() {
    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Currency
            <div className="title--bottom"></div>
          </div>

          <div className="card-body">
            
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantCurrencyDataColumn}
                  className="table1"
                  tableName="Merchant Currency"
                  keyField="srNo"
                  createBtn={<ToggleModal {...this.createMerchantCurrency()} />}
                  onRefresh={this.getMerchantCurrencies.bind(this)}
                  {...this.renderTableData("merchantCurrencies")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantCurrency;
