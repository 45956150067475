import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { DynamicActionBtn } from "../common/RenderCustomView";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const merchantWithdrawalDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "type",
    text: "Type",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "to",
    text: "To",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
    formatter: (text) => text ? text : "--"
    
  },
  {
    dataField: "tag",
    text: "Tag",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
    formatter: (text) => text ? text : "--"
  },
  {
    dataField: "note",
    text: "Note",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
    formatter: (text) => text ? text : "--"
  },
  {
    dataField: "status",
    text: "Status",
    classes: "highlight",
    sort: true,
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

export class MerchantWithdrawal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantWithdrawalList: { data: [], loading: false, totalCount: 0 },
      currencyList: []
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantWithdrawalList();
  }

  getMerchantWithdrawalList() {
    const processApi = `/admin-dashboard-api-service/merchant-withdrawal/process-merchant-withdrawal`;
    const cancelApi = `/admin-dashboard-api-service/merchant-withdrawal/cancel-merchant-withdrawal`;
    this.generalStateUpdater("merchantWithdrawalList", {
      path: `admin-dashboard-api-service/merchant-withdrawal/get-all-merchant-withdrawal`,
      method: "POST",
      postProcessor: (e) => {

        const Process = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={processApi}
            options={{
              variant: "success",
              name: "Process",
              data: {
                withdrawId: e.uniqueId,
              },
            }}
          />
        );

        const Cancel = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={cancelApi}
            options={{
              variant: "danger",
              name: "Cancel",
              data: {
                withdrawId: e.uniqueId,
              },
            }}
          />
        );

        return {
          ...e,
          actions: [
            {
              modalName: "View Merchant Withdrawal",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Process,
            Cancel
          ],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("type");
    return {
      data: this.state[name].data
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if(e.updatedAt != null) {
          e.updatedAt = moment(e.updatedAt).format('YYYY-MM-DD HH:mm:ss');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }


  render() {
    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Withdrawals
            <div className="title--bottom"></div>
          </div>

          <div className="card-body">
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantWithdrawalDataColumn}
                  className="table1"
                  tableName="Merchant Withdrawals"
                  keyField="srNo"
                  onRefresh={this.getMerchantWithdrawalList.bind(this)}
                  {...this.renderTableData("merchantWithdrawalList")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantWithdrawal;
