import { DISABLE_MODAL, VISIBLE_MODAL } from '../actions/actionTypes';

const initialState = {
  isVisible: false,
  Component: '',
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case VISIBLE_MODAL:
      state.isVisible = true;
      state.Component = action.Component;
      return { ...state };

    case DISABLE_MODAL:
      state.isVisible = false;
      state.Component = '';
      return { ...state };
    default:
      return state;
  }
};

export default ModalReducer;
